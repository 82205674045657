<app-topsubnavigation *ngIf="isallsteps" ></app-topsubnavigation>


<form>

<h1 class="sh-title-1 text-uppercase">Steps</h1>

<!-- Widget -->
<div class="sh-table-widget-box mt-5">
  <div class="sh-widget-box-header">
      <div class="row">
          <div class="col-lg-3">
                  <input class="sh-audit-table-search" type="text" name="searchTerm" [(ngModel)]="searchTerm" />
                  <span class="ml-3" *ngIf="loading$ | async">Loading...</span>
          </div>
          <div class="col-lg-9 text-right mt-3 mt-lg-0">
            <button type="button" class="btn sh-lg-green-button" (click)="addStep()">
                <img src="/assets/images/add-icon.svg" alt="" class="mr-2" />
                Add Step
            </button>
        </div>
      </div>
  </div>


      <!-- <div class="form-group form-inline">
        Search: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="searchTerm"/>
        <span class="ml-3" *ngIf="loading$ | async">Loading...</span>
      </div> -->




      <div class="table-responsive">
        <table class="table sh-audit-table mt-5">
        <thead>
          <tr>
            <th scope="col" sortable="id" (sort)="onSort($event)" class="sh-title-7">ID</th>
            <th scope="col" sortable="question"  (sort)="onSort($event)" class="sh-title-7">Question</th>
            <th scope="col" class="sh-title-7">Created On</th>
            <th scope="col" width="100px" class="sh-title-7 text-center">Edit</th>
            <th scope="col" width="100px" class="sh-title-7 text-center">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr class="success" *ngFor="let a of steps$| async">
            <td scope="row">{{a.id}}</td>
            <td>{{a.question | slice:0:50}}</td>
            <td>{{a.createdDate | date:'yyyy-MM-ddTHH:mm'}}</td>
            <td>
              <button type="button" class="btn sh-sm-blue-button" (click)="editStep(a)"  ngbTooltip="Edit Step"> Edit</button>
            </td>
            <td>
              <button type="button"  class="btn sh-sm-red-button" (click)="deleteStep(a)" ngbTooltip="Delete Step">Delete </button>
          </td>
          </tr>
        </tbody>
      </table>
      </div>


    </div>
    <!-- /Widget -->

    <div class="d-flex p-2 float-right mt-3">
      <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="pageSize">
        <option [ngValue]="5">5 items per page</option>
        <option [ngValue]="10">10 items per page</option>
        <option [ngValue]="20">20 items per page</option>
        <option [ngValue]="50">50 items per page</option>
      </select>
  
      <ngb-pagination
        [collectionSize]="(total$ | async)!" [(page)]="page" [pageSize]="pageSize" [maxSize]="10" class="sh-pagination">
      </ngb-pagination>
  
    </div>

  </form>






<ng-template #addStepModal let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h4  class="modal-title">New Step</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
   <app-createstep (stepCreated)="stepCreated($event)"></app-createstep>
  </div>
  <div class="modal-footer">
      <!-- <button type="button" id="cancel-edit-btn" class="btn btn-primary" (click)="c('Close click')">Cancel</button> -->
  </div>
</ng-template>

<ng-template #editStepModal let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h4  class="modal-title">Step</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
   <app-editstep (stepUpdated)="stepUpdated($event)"></app-editstep>
  </div>
  <div class="modal-footer">
      <!-- <button type="button" id="cancel-edit-btn" class="btn btn-primary" (click)="c('Close click')">Cancel</button> -->
  </div>
</ng-template>
