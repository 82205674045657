import { Component, OnInit } from '@angular/core';
import {Observable , BehaviorSubject , Subject , of, observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditsService } from 'src/app/services/audits.service';
import { Audits } from 'src/app/models/audits';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

   activeAudits: Audits[] = [];
   monthResults : any[] = 
  [{"month" : "January" , "count" : 25 } , 
  {"month" : "February" , "count" : 45 } , 
  {"month" : "March" , "count" : 30 } , 
  {"month" : "April" , "count" : 50 } , 
  {"month" : "May" , "count" : 40 } , 
  {"month" : "June" , "count" : 65 } , 
  {"month" : "July" , "count" : 48 } , 
  {"month" : "August" , "count" : 30 } , 
  {"month" : "September" , "count" : 25 } 

  ] ; 

  constructor( public auditservice: AuditsService, private SpinnerService: NgxSpinnerService, private router: Router) 
  { 
    this.loadaudits();
  }

  ngOnInit(): void {
   
  }


  private loadaudits() {
    this.SpinnerService.show();
    this.auditservice.getActiveAudits().subscribe((res) => {
      this.SpinnerService.hide();
      this.activeAudits = res;
      if(this.activeAudits.length > 6)
      {
        this.activeAudits = this.activeAudits.slice(0, 6);
      }
     
    })
      // tslint:disable-next-line: no-unused-expression
      , (err: any) => {
        this.SpinnerService.hide();
        console.log(err);
      };
  }


}
