import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import {AuditsService} from '../../../services/audits.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-createaudit',
  templateUrl: './createaudit.component.html',
  styleUrls: ['./createaudit.component.css']
})
export class CreateauditComponent implements OnInit {
  form: FormGroup;

  constructor(public auditsService: AuditsService,
              private SpinnerService: NgxSpinnerService,
              private router: Router) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      Name: new FormControl('', [Validators.required])
    });
  }
  get f(){
    return this.form.controls;
  }
  submit(){
    console.log(this.form.value);
    this.SpinnerService.show();
    this.auditsService.create(this.form.value).subscribe(res => {
         console.log('Audit created successfully!');
         const auditId = res as number;
         if (auditId === 0)
         {
          this.router.navigateByUrl('listaudits');
         }
         else
         {
          window.localStorage.removeItem('auditId');
          window.localStorage.setItem('auditId', auditId.toString());
          this.router.navigate(['editaudit']);
         }
    });
  }

}
