
<app-topnavigation></app-topnavigation>

<app-leftnavigation></app-leftnavigation>

<!-- ===== Dashboard Container ===== -->
<div class="container-fluid">		
<!-- ===== Main Content Area ===== -->	
<div class="sh-container">

  
<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Loading. </p>
</ngx-spinner>

</div>
<!-- ===== Main Content Area ===== -->     
</div>
<!-- ===== /Dashboard Container ===== -->




<!-- <div *ngIf="isAuthenticated">
    <ul class="nav flex-column flex-sm-row nav-tabs">
      <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['listaudits']">Library</a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['createreports']">Reports</a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['users']">Admin</a>
      </li>
    </ul>
  </div> -->
  

