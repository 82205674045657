import { Injectable , PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Audits } from '../models/audits';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuditsService {

  constructor(private http: HttpClient  ) { }

  getActiveAudits(): Observable<Audits[]>{
    return this.http.get<Audits[]>(environment.auditUrl + 'api/Audits/')
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  getAuditDetails(id: number): Observable<Audits>{
    return this.http.get<Audits>(environment.auditUrl + 'api/audits/GetDetailedAudit/' + id);
  }

  getAuditDetailsForReport(id: number): Observable<Audits>{
    return this.http.get<Audits>(environment.auditUrl + 'api/audits/GetAuditDetailsForReports/' + id);
  }


  create(audit: Audits){
    audit.isdeleted = false;
    audit.status = 1;
    return this.http.post<number>(environment.auditUrl + 'api/audits/', audit)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(audit: Audits){
    audit.isdeleted = false;
    audit.status = 1;
    return this.http.put(environment.auditUrl + 'api/audits/' + audit.id, audit)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(audit: Audits): Observable<Audits[]>{
    return this.http.delete<Audits[]>(environment.auditUrl + 'api/audits/' + audit.id)
    .pipe(map((res: Audits[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }

}
