import { Component, OnInit, PipeTransform, Input, EventEmitter, Output, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Categories } from 'src/app/models/categories';
import { NgbModalRef, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { PaginationState } from 'src/app/utilities/paginationState';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DecimalPipe } from '@angular/common';
import { debounceTime, tap, switchMap, delay } from 'rxjs/operators';
import { NgbdSortableHeader, SortDirection, SortEvent } from 'src/app/sortable.directive';
import { CategoryService } from 'src/app/services/category.service';

interface SearchResult {
  categories: Categories[];
  total: number;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(categories: Categories[], column: string, direction: string): Categories[] {
  if (direction === '') {
    return categories;
  } else {
    return [...categories].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(category: Categories, term: string, pipe: PipeTransform) {
  return category.name.toLowerCase().includes(term.toLowerCase())
    || pipe.transform(category.id).includes(term);

}

@Component({
  selector: 'app-listcategories',
  templateUrl: './listcategories.component.html',
  styleUrls: ['./listcategories.component.css']
})
export class ListcategoriesComponent implements OnInit {
// tslint:disable-next-line: no-input-rename
@Input() categories: Categories[] = [];
@Input() isallcategories = false;
@Output() eventCategoryCreated = new EventEmitter();
@Output() eventCategoryUpdated = new EventEmitter();
@Output() eventCategoryDeleted = new EventEmitter();

modalRef: NgbModalRef;

@ViewChild('addcategorymodal') addcategorymodal: ElementRef;
@ViewChild('editcategorymodal') editcategorymodal: ElementRef;
@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

categories$: BehaviorSubject<Categories[]> = new BehaviorSubject<Categories[]>([]);
total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true) ;


// tslint:disable-next-line: variable-name
private _search$ = new Subject<void>();

 state: PaginationState = {
  page: 1,
  pageSize: 10,
  searchTerm: '',
  sortColumn: '',
  sortDirection: ''
};

constructor(private categoryService: CategoryService,
            private router: Router,
            private spinnerService: NgxSpinnerService,
            private modalService: NgbModal,
            private activeModal: NgbActiveModal,
            private pipe: DecimalPipe) {
              if (this.router.url === '/listcategories')
              {
                this.isallcategories = true;
              }
 }

ngOnInit(): void {
 this.loadcategories();
}

// tslint:disable-next-line: use-lifecycle-interface
ngOnChanges(): void{

  if (!this.isallcategories)
  {
    this._search$.pipe(
      tap(() => this.loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this.loading$.next(false))
    ).subscribe(result => {
      this.categories$.next(result.categories);
      this.total$.next(result.total);
    });

    this._search$.next();
  }
}

categorycreated(t: any){
  debugger;
  if (t && t.categoryId)
  {
      this.eventCategoryCreated.emit({ event, categoryId: t.categoryId });
  }
  this.modalRef.close();
  this.loadcategories();
}

categoryupdated(t: any){
  this.eventCategoryUpdated.emit();
  this.modalRef.close();
  this.loadcategories();
}

private loadcategories() {
 if (this.isallcategories) {
    this.spinnerService.show();

    this.categoryService.GetCategories().subscribe((res) => {
      this.spinnerService.hide();
      this.categories = res;

      this._search$.pipe(
        tap(() => this.loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this.loading$.next(false))
      ).subscribe(result => {
        this.categories$.next(result.categories);
        this.total$.next(result.total);
      });

      this._search$.next();
    })
      // tslint:disable-next-line: no-unused-expression
      , (err: any) => {
        this.spinnerService.hide();
        console.log(err);
      };
  }
}

addCategory(): void{
  this.modalRef = this.modalService.open(this.addcategorymodal, { size: 'md', backdrop: 'static'});
}

editCategory(e): void{
  window.localStorage.removeItem('categoryId');
  window.localStorage.setItem('categoryId', e.id.toString());
  this.modalRef = this.modalService.open(this.editcategorymodal, { size: 'xl', backdrop: 'static'});
}

deleteCategory(category: Categories): void{
  if (confirm('Are you sure to delete ' + category.name + '?')) {
    if (this.isallcategories)
    {
      this.spinnerService.show();
      this.categoryService.delete(category.id).subscribe((res) => {
         console.log('Category deleted successfully!');
         this.categories = res;

         this._search$.pipe(
          tap(() => this.loading$.next(true)),
          debounceTime(200),
          switchMap(() => this._search()),
          delay(200),
          tap(() => this.loading$.next(false))
        ).subscribe(result => {
          this.categories$.next(result.categories);
          this.total$.next(result.total);
        });

         this._search$.next();

         this.spinnerService.hide();
       })
       // tslint:disable-next-line: no-unused-expression
       , (err: any) => {
         this.spinnerService.hide();
         console.log(err);
       };
    }

    this.eventCategoryDeleted.emit({ event, categoryId: category.id });
  }
}

get page() { return this.state.page; }
get pageSize() { return this.state.pageSize; }

// tslint:disable-next-line: adjacent-overload-signatures
set page(page: number) { this._set({page}); }
// tslint:disable-next-line: adjacent-overload-signatures
set pageSize(pageSize: number) { this._set({pageSize}); }
set searchTerm(searchTerm: string) { this._set({searchTerm}); }
set sortColumn(sortColumn: string) { this._set({sortColumn}); }
set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

private _set(patch: Partial<PaginationState>) {
  Object.assign(this.state, patch);
  this._search$.next();
}

private _search(): Observable<SearchResult> {

  const {sortColumn, sortDirection, pageSize, page, searchTerm} = this.state;
  // 1. sort
  let categories  = sort(this.categories, sortColumn, sortDirection);

  // 2. filter
  if (categories)
  {
   categories = categories.filter(x => matches(x, searchTerm, this.pipe));
   const total = categories.length;
    // 3. paginate
   categories = categories.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

   return of({categories, total});

  }

  return of({categories, total: 0});

}

onSort({column, direction}: SortEvent) {

  // resetting other headers
  this.headers.forEach(header => {
    if (header.sortable !== column) {
      header.direction = '';
    }
  });

  this.sortColumn = column;
  this.sortDirection = direction;
}
}
