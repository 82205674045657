<div class="container">
  <h5>Sub Sections</h5>
  <div class="card">
    <app-listsubsections
        [isallsubsections]="false"
        [subsections]='subsections'
        (eventSubSectionCreated)="subsectioncreated($event)"
        (eventSubSectionUpdated)="subsectionupdated($event)"
        (eventSubSectionDeleted)="subsectiondeleted($event)"></app-listsubsections>
  </div>
</div>
