/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//   "aws_project_region": "us-east-1",
//   "aws_content_delivery_bucket": "safetyuditroest3-20200618161311-hostingbucket-saauprote",
//   "aws_content_delivery_bucket_region": "us-east-1",
//   "aws_content_delivery_url": "https://djx3h191rc8os.cloudfront.net",
//   "aws_cognito_region": "us-east-1",
//   "aws_user_pools_id": "us-east-1_lhRqEdUYt",
//   "aws_user_pools_web_client_id": "2okevvloapmgdc2bklaqak6tt2",
//   "oauth": {
//       "domain": "openidconnecttestchaminda.auth.us-east-1.amazoncognito.com",
//       "scope": [
//           "phone",
//           "email",
//           "openid",
//           "profile",
//           "aws.cognito.signin.user.admin"
//       ],
//       "redirectSignIn": "http://localhost:4200/listaudits",
//       "redirectSignOut": "http://localhost:4200/login",
//       "responseType": "code"
//   }
// };


const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_content_delivery_bucket": "safetyuditroest3-20200618161311-hostingbucket-saauprote",
  "aws_content_delivery_bucket_region": "us-east-1",
  "aws_content_delivery_url": "www.auditpro.safetyhiveuat.com",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_lhRqEdUYt",
  "aws_user_pools_web_client_id": "524vcpudlpjo0f3ca7mc4av8ei",
  "oauth": {
      "domain": "openidconnecttestchaminda.auth.us-east-1.amazoncognito.com",
      "scope": [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": "https://www.auditpro.safetyhiveuat.com/listaudits",
      "redirectSignOut": "https://www.auditpro.safetyhiveuat.com/login",
      "responseType": "code"
  }
};


export default awsmobile;
