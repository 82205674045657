import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Employee, EmployeeRoleToLabel } from 'src/app/models/employee';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-updateuser',
  templateUrl: './updateuser.component.html',
  styleUrls: ['./updateuser.component.css']
})
export class UpdateuserComponent implements OnInit {

  user: Employee  = {} as Employee;
  employeeId: number;
  userRoles = EmployeeRoleToLabel;
  isemployeeaudituser = false;

  modalRef: NgbModalRef;

  @ViewChild('addauditprologinmodal') addauditprologinmodal: ElementRef;

  public EmployeeRoleToLabel = EmployeeRoleToLabel;

  constructor(private userService: UserService,
              private router: Router,
              private modalService: NgbModal,
              private SpinnerService: NgxSpinnerService) {
              }

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser(): void{
    const id = window.localStorage.getItem('employeeId');
    if (!id) {
      console.log('No User Id found');
      return;
    }
    this.SpinnerService.show();
    this.employeeId = Number(id);
    this.userService.getUserDetails(this.employeeId)
      .subscribe( data => {
        this.SpinnerService.hide();
        this.user = data;
      });
    this.userService.IsEmployeeAuditUser(this.employeeId)
      .subscribe( data => {
        this.SpinnerService.hide();
        this.isemployeeaudituser = data;
      });
  }

  openaddauditprologin(): void{
    this.modalRef = this.modalService.open(this.addauditprologinmodal, { size: 'md', backdrop: 'static'});
  }

  save(user: Employee): void{
    this.SpinnerService.show();
    this.userService.create(user).subscribe( data => {
      this.SpinnerService.hide();
      alert('Audit Pro Login created successfully. User will receive confirmation email shortly.');
      this.modalService.dismissAll('Reason');
    });
  }
}
