import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Inspections } from 'src/app/models/inspections';
import { InspectionService } from 'src/app/services/inspection.service';
import { StepService } from 'src/app/services/step.service';

@Component({
  selector: 'app-editinspection',
  templateUrl: './editinspection.component.html',
  styleUrls: ['./editinspection.component.css']
})

export class EditinspectionComponent implements OnInit {
  inspection: Inspections  = {} as Inspections;
  inspectionId: number;
  editForm: any;
  @Output() inspectionUpdated = new EventEmitter();

  constructor(private inspectionService: InspectionService,
              private SpinnerService: NgxSpinnerService,
              private activeModal: NgbModal,
              private stepService: StepService) {
                this.loadinspection();
               }

  ngOnInit(): void {
  }

  loadinspection(): void{
    const id = window.localStorage.getItem('inspectionId');
    if (!id) {
      console.log('No Inspection Id found');
      return;
    }
    this.SpinnerService.show();
    this.inspectionId = Number(id);
    this.inspectionService.GetInspectionDetail(this.inspectionId)
      .subscribe( data => {
        this.SpinnerService.hide();
        this.inspection = data;
      });
  }

  Save(inspections: Inspections): void {
    this.SpinnerService.show();
    this.inspectionService.update(inspections).subscribe(res => {
        inspections = res;
        console.log('Inspection created successfully!');
        this.inspectionUpdated.emit({ event, inspectionId: inspections.id });
        // this.activeModal.dismissAll('Reason');
    });
  }
}
