<div class="container">
  <form #editForm="ngForm">
    <div class="form-group">
      <input type="hidden" name="id" [(ngModel)]='section.id'>
      <label for="Name">Name</label>
      <input type="text" class="form-control" name="Name" [(ngModel)]='section.name' size="100">
    </div>
    <div calss="card">
      <app-sectionsteps></app-sectionsteps>
    </div>
    <div calss="card">
      <app-sectioninspections></app-sectioninspections>
    </div>
    <div calss="card">
       <app-sectionsubsections></app-sectionsubsections>
    </div>
    <input type="button" class="btn btn-primary" value="Save" (click)="Save(editForm.value)"  ngbTooltip="Save Category Section">
  </form>
 </div>
