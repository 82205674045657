import { Component,OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Steps } from 'src/app/models/steps';

@Component({
  selector: 'app-shortanswer',
  templateUrl: './shortanswer.component.html',
  styleUrls: ['./shortanswer.component.css']
})
export class ShortanswerComponent implements OnInit {

  @Input() step: Steps;
  @Output() stepWithObservations : EventEmitter<Steps> = new EventEmitter<Steps>();
  stepWithOptions: Steps;

  shortAnswer : string = "";

  constructor() { }

  ngOnInit(): void {
    this.analyseInput() ;
  }

  analyseInput()
  {
    if(this.step != null )
    {
      this.stepWithOptions = this.step ;

      if(this.stepWithOptions.observations != null  )
      {
        if(this.stepWithOptions.observations [0] != null  )
        {
          this.shortAnswer = this.stepWithOptions.observations[0].AnsShortAnswerText;
        
        }
       
      }
    }
  }

  textChange(answer: string)
  {
    this.shortAnswer = answer ;
    let observation :any =  ({StepId: this.stepWithOptions.id ,  AnsShortAnswerValue : answer  , AnsShortAnswerText: answer });
    this.stepWithOptions.observations = [] ; 
    this.stepWithOptions.observations.push(observation) ; 

    this.stepWithObservations.emit(this.stepWithOptions);
  }

}
