import { Component, OnInit , NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { Auth, Hub } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private amplifyService: AmplifyService,
              private zone: NgZone,
              private spinner: NgxSpinnerService,
              private router: Router) {
    this.spinner.show();
    // Used for listening to login events
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'cognitoHostedUI' || event === 'signedIn') {
        console.log(event);
        // this.zone.run(() => this.router.navigate(['/companies']));
        this.zone.run(() => this.router.navigate(['/dashboard']));
      } else {
        this.spinner.hide();
      }
    });

    // currentAuthenticatedUser: when user comes to login page again
    Auth.currentAuthenticatedUser()
      .then(() => {
        // this.router.navigate(['/companies'], { replaceUrl: true });
        this.router.navigate(['/dashboard'], { replaceUrl: true });
      }).catch((err) => {
        this.spinner.hide();
        //Auth.federatedSignIn();
        console.log(err);
      });
   }

  ngOnInit(): void {
  }

  onLoginClick() {
    this.spinner.show();
    Auth.federatedSignIn();
  }
}
