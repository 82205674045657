

<table>
	<thead>
		<tr>
      <th>Question</th>
			<th *ngFor="let a of  stepWithOptions.stepSelectOptions">{{a.text}} </th>
		</tr>
  </thead>
<tr>
  <td>{{stepWithOptions.id}} . {{stepWithOptions.question}}</td>
	<td  *ngFor="let a of stepWithOptions.stepSelectOptions">
    <input type="radio" name="{{stepWithOptions.question}}"   value="{{a.id}}"  
      [checked]="selectedAnswer == a.id" (change)="changeScaleAnswer(a)" /> 
  </td>
</tr>

</table>

