<div class="container">

  <form [formGroup]="stepForm">

      <div class="form-group">
          <input formControlName="id" type="hidden">
          <input formControlName="optionsGroupId" type="hidden">
          <label for="question">Question:</label>
          <input
              id="question"
              name="question"
              type="text"
              formControlName="question"
              class="form-control">
          <label for="question">Question Type:</label>
          <select  id="questiontype" name="questiontype" class="form-control" formControlName="questiontype">
            <option value="" disabled>Select Question Type</option>
            <option *ngFor="let type of questiontypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>

          <div *ngIf="stepForm.value.questiontype == '4'  ||stepForm.value.questiontype == '5' ||stepForm.value.questiontype == '6' " >
          <label for="options">Options:</label>

          <div formArrayName="selectoptions"
            *ngFor="let item of getControls(); let i = index;">
            <div [formGroupName]="i">
              <input formControlName="id" type="hidden">
              <input formControlName="delete" type="hidden">
              <input formControlName="text" placeholder="Option text" [readOnly] = "item.value.delete">

              <button class="btn btn-primary" (click)="removeselectoption($event.target, i)" >-</button>
            </div>
          </div>
          <button class="btn btn-primary" (click)="addselectoption()" >+</button>
          </div>
      </div>

      <button class="btn btn-primary" type="submit" [disabled]="!stepForm.valid"  (click)="Save()" >Save</button>


  </form>
</div>
