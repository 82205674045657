import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Categories } from '../models/categories';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuditCategories } from '../models/audit-categories';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  GetCategories(): Observable<Categories[]>{
    return this.http.get<Categories[]>(environment.auditUrl + 'api/Categories/')
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  GetAuditCategories(auditId: number): Observable<Categories[]>{
    return this.http.get<Categories[]>(environment.auditUrl + 'api/Categories/GetAuditCategories/' + auditId)
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  GetCategory( categoryId: number): Observable<Categories>{
    return this.http.get<Categories>(environment.auditUrl + 'api/Categories/' + categoryId)
            .pipe(map((res: any) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  create(categories: Categories){
    return this.http.post(environment.auditUrl + 'api/Categories/', categories)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  createauditcategory(auditId: number, categoryId: number){
    return this.http.post(environment.auditUrl + 'api/Categories/CreateAuditCategory/' + auditId + '/' + categoryId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(auditCategories: AuditCategories){
    return this.http.put(environment.auditUrl + 'api/Categories/UpdateAuditCategory/', auditCategories)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(categoryId: number): Observable<Categories[]>{
    return this.http.delete<Categories[]>(environment.auditUrl + 'api/Categories/' + categoryId)
    .pipe(map((res: Categories[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deleteauditcategory(auditId: number, categoryId: number): Observable<Categories[]>{
    return this.http.delete<Categories[]>(environment.auditUrl + 'api/Categories/DeleteAuditCategory/' + auditId + '/' + categoryId)
    .pipe(map((res: Categories[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }

}
