import { Component, Input ,OnInit , Output , EventEmitter } from '@angular/core';
import { StepService } from 'src/app/services/step.service';
import { Steps } from 'src/app/models/steps';

@Component({
  selector: 'app-singlechoiceanswer',
  templateUrl: './singlechoiceanswer.component.html',
  styleUrls: ['./singlechoiceanswer.component.css']
})
export class SinglechoiceanswerComponent implements OnInit {

  @Input() step: Steps;
  @Output() stepWithObservations : EventEmitter<Steps> = new EventEmitter<Steps>();
  stepWithOptions: Steps;
  selectedAnswer:number;

  constructor(private stepService: StepService) {

  }

  ngOnInit(): void {
    this.analyseInput();
  }

  analyseInput()
  {
    
    if(this.step != null )
    {
      this.stepWithOptions = this.step ;

      
      if(this.stepWithOptions.observations [0] != null  )
      {
        if(this.stepWithOptions.observations[0].AnsSingleChoiceNavigation != null )
        {
          this.selectedAnswer = this.stepWithOptions.observations[0].AnsSingleChoiceNavigation.id;
        }
        
      }
     
    }
  }


  changeAnswer(item)
  {
    let selctedOption : any =item;
    let observation :any =  ({StepId: this.stepWithOptions.id ,  AnsSingleChoiceNavigation : selctedOption });
    this.stepWithOptions.observations =[] ;
    this.stepWithOptions.observations.push(observation);

    this.stepWithObservations.emit(this.stepWithOptions);
  }



}
