

<form>

  <h1 class="sh-title-1 text-uppercase">Users</h1>

  <!-- Widget -->
<div class="sh-table-widget-box mt-5">
  <div class="sh-widget-box-header">
      <div class="row">
          <div class="col-lg-3">
                  <input class="sh-audit-table-search" type="text" name="searchTerm" [(ngModel)]="searchTerm" />
                  <span class="ml-3" *ngIf="loading$ | async">Loading...</span>
          </div>
      </div>
  </div>

  <div class="table-responsive">
    <table class="table sh-audit-table mt-5">
        <thead>
          <tr>
            <th scope="col" sortable="id" (sort)="onSort($event)" class="sh-title-7" width="100px">ID</th>
            <th scope="col" sortable="firstName"  (sort)="onSort($event)" class="sh-title-7">First Name</th>
            <th scope="col" sortable="lastName"  (sort)="onSort($event)" class="sh-title-7">Last Name</th>
            <th scope="col" width="100px"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="success" *ngFor="let a of employees$| async">
            <td scope="row">{{a.employeeId}}</td>
            <td>{{a.firstName | slice:0:50}}</td>
            <td>{{a.lastName | slice:0:50}}</td>
            <td>
              <button type="button" class="btn sh-sm-blue-button" (click)="editUser(a)">View</button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>


  </div>
  <!-- /Widget -->

  <div class="d-flex p-2 float-right mt-3">
    <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="pageSize">
      <option [ngValue]="5">5 items per page</option>
      <option [ngValue]="10">10 items per page</option>
      <option [ngValue]="20">20 items per page</option>
      <option [ngValue]="50">50 items per page</option>
    </select>

    <ngb-pagination
      [collectionSize]="(total$ | async)!" [(page)]="page" [pageSize]="pageSize" [maxSize]="10" class="sh-pagination">
    </ngb-pagination>

  </div>


</form>

    

<ng-template #updateUserModal let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h4  class="modal-title">User</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
   <app-updateuser></app-updateuser>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Loading. </p>
</ngx-spinner>
