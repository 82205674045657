import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Sections } from 'src/app/models/sections';
import { Categories } from 'src/app/models/categories';
import { CategorySections } from 'src/app/models/category-sections';
import { Router } from '@angular/router';
import { SectionsService } from 'src/app/services/sections.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-editsection',
  templateUrl: './editsection.component.html',
  styleUrls: ['./editsection.component.css']
})
export class EditsectionComponent implements OnInit {

  section: Sections = {} as Sections;
  category: Categories = {} as Categories;
  categorySections: CategorySections = {} as CategorySections;

  @Input() categoryId: number;
  @Output() sectionupdated = new EventEmitter();

  constructor(private router: Router,
              private sectionsService: SectionsService,
              private SpinnerService: NgxSpinnerService,
              private activeModal: NgbModal) {

               }

  ngOnInit(): void {
    this.loadsection();
  }

  loadsection(): void{
    debugger;
    const sectionId = window.localStorage.getItem('sectionId');
    if (!sectionId) {
      alert('Invalid action.');
      return;
    }

    this.SpinnerService.show();
    this.sectionsService.getsection(Number(sectionId))
      .subscribe( data => {
        this.section = data as Sections;
        this.SpinnerService.hide();
      });
  }

  Save(section: Sections): void {
    this.SpinnerService.show();

    // tslint:disable-next-line: prefer-const
    let categorySections: CategorySections =  new CategorySections();
    categorySections.section = section;
    categorySections.categoryId = this.categoryId;

    this.sectionsService.update(categorySections).subscribe(res => {
        console.log('Section created successfully!');
        this.sectionupdated.emit({ event, sectionId: categorySections.section.id });
    });
  }
}
