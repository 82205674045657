<div class="container">
  <form #updateForm="ngForm">
    <input type="hidden" name="id" [(ngModel)]='inspection.id' size="100">
    <div class="form-group">
      <label for="Name">Name</label>
      <input type="text" class="form-control" name="Name" [(ngModel)]='inspection.name' size="100">
    </div>
    <input type="button" class="btn btn-primary" value="Save" (click)="Save(updateForm.value)"  ngbTooltip="Save Inspection">
    <div calss="card">
      <app-inspectionsteps></app-inspectionsteps>
    </div>
  </form>
  </div>
