import { Component, Directive, EventEmitter, Input, Output, QueryList, ViewChildren, OnInit, ViewChild, ElementRef , PipeTransform } from '@angular/core';
import { Observable, throwError  , BehaviorSubject  , Subject  , of } from 'rxjs';
import { StepService } from 'src/app/services/step.service';
import { Steps } from 'src/app/models/steps';
import { Router } from '@angular/router';
import {NgbdSortableHeader, SortEvent} from 'src/app/sortable.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {DecimalPipe} from '@angular/common';
import {PaginationState } from 'src/app/utilities/paginationState';
import {SortDirection } from 'src/app/sortable.directive';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';

interface SearchResult {
  companies: Company[];
  total: number;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(companies: Company[], column: string, direction: string): Company[] {
  if (direction === '') {
    return companies;
  } else {
    return [...companies].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(company: Company, term: string, pipe: PipeTransform) {

  return company.companyName.toLowerCase().includes(term.toLowerCase())
  || pipe.transform(company.companyId).includes(term);

}


@Component({
  selector: 'app-listcompanies',
  templateUrl: './listcompanies.component.html',
  styleUrls: ['./listcompanies.component.css'] ,
  providers: [CompanyService, DecimalPipe]
})
export class ListcompaniesComponent implements OnInit {

  companies$: BehaviorSubject<Company[]> =  new BehaviorSubject<Company[]>([]);
  total$: BehaviorSubject<number> =  new BehaviorSubject<number>(0);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true) ;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @ViewChild('branchesModal') branchesModal: ElementRef;

  constructor(public companyService: CompanyService,
              private router: Router,
              private SpinnerService: NgxSpinnerService,
              private pipe: DecimalPipe,
              private modalService: NgbModal) {
    this.loadCompanies();
   }

  companies: Company[] = [] ;
  state: PaginationState = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  private _search$ = new Subject<void>();

  private loadCompanies() {
    this.SpinnerService.show();
    this.companyService.getActiveCompanies().subscribe((res) => {
      this.SpinnerService.hide();
      this.companies = res;
      this._search$.pipe(
        tap(() => this.loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this.loading$.next(false))
      ).subscribe(result => {
        this.companies$.next(result.companies);
        this.total$.next(result.total);
      });

      this._search$.next();

    })
      // tslint:disable-next-line: no-unused-expression
      , (err: any) => {
        this.SpinnerService.hide();
        console.log(err);
      };
  }

  ngOnInit(): void { }

  addCompany(): void{
    this.router.navigateByUrl('newstep');
  }

  selectBranch(e): void{
    window.localStorage.removeItem('companyId');
    window.localStorage.setItem('companyId', e.companyId.toString());

    this.modalService.open(this.branchesModal, { size: 'xl', backdrop: 'static'});

  }

  deleteCompany(step: Steps): void{
    if (confirm('Are you sure to delete ' + step.question + '?')) {
      // this.SpinnerService.show();
      // this.companyService.delete(step).subscribe((res) => {
      //     console.log('Post created successfully!');
      //     this.companies = res;
      //     this.SpinnerService.hide();
      //   });
    }
  }

  get page() { return this.state.page; }
  get pageSize() { return this.state.pageSize; }

  set page(page: number) { this._set({page}); }
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set searchTerm(searchTerm: string) { this._set({searchTerm}); }
  set sortColumn(sortColumn: string) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

  private _set(patch: Partial<PaginationState>) {
    Object.assign(this.state, patch);
    this._search$.next();
  }


  private _search(): Observable<SearchResult> {

    const {sortColumn, sortDirection, pageSize, page, searchTerm} = this.state;
    // 1. sort
    let companies  = sort(this.companies, sortColumn, sortDirection);

    // 2. filter
    companies = companies.filter(x => matches(x, searchTerm, this.pipe));
    const total = companies.length;

    // 3. paginate
    companies = companies.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

    return of({companies, total});

  }

  onSort({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;
  }

}
