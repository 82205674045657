

<div class = "row">
  <label>  {{stepWithOptions.id}} . {{stepWithOptions.question}}</label>
</div>

<div class="form-check" *ngFor="let a of stepWithOptions.stepSelectOptions">
  <label>
    <input type="checkbox" [value]="a.id" [checked]="checked(a.id)"
    (change)="onCheckboxChange($event)" />
    {{a.text}}
  </label>
</div>
