import { Component, OnInit,Input , Output  , EventEmitter} from '@angular/core';
import { StepWithOptions } from 'src/app/models/step-with-options';
import { StepService } from 'src/app/services/step.service';
import { Steps } from 'src/app/models/steps';

@Component({
  selector: 'app-scaleanswer',
  templateUrl: './scaleanswer.component.html',
  styleUrls: ['./scaleanswer.component.css']
})
export class ScaleanswerComponent implements OnInit {

 @Input() step: Steps;
 @Output() stepWithObservations : EventEmitter<Steps> = new EventEmitter<Steps>();
 stepWithOptions: Steps;
 selectedAnswer:number = 0;

  constructor(private stepService: StepService) { }

  ngOnInit(): void {
    this.analyseInput() ;
  }

  analyseInput()
  {
    if(this.step != null )
    {
      this.stepWithOptions = this.step ;
      if(this.stepWithOptions.observations != null  )
      {
        if(this.stepWithOptions.observations [0] != null  )
        {
          this.selectedAnswer = this.stepWithOptions.observations[0].AnsScale;
        }
       
      }
    }
  }

  changeScaleAnswer (item) {
    let selctedOption : any = item.id;
    let observation :any =  ({StepId: this.stepWithOptions.id ,  AnsScale : selctedOption });
    this.stepWithOptions.observations = [] ; 
    this.stepWithOptions.observations.push(observation) ;

    this.stepWithObservations.emit(this.stepWithOptions);

  }


  




}
