import { Component, QueryList, ViewChildren, OnInit , PipeTransform  } from '@angular/core';
import {Observable , BehaviorSubject , Subject , of } from 'rxjs';
import { AuditsService } from 'src/app/services/audits.service';
import { Audits } from 'src/app/models/audits';
import { Router } from '@angular/router';
import {NgbdSortableHeader, SortEvent} from 'src/app/sortable.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import {DecimalPipe} from '@angular/common';
import {SortDirection } from 'src/app/sortable.directive';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {PaginationState } from 'src/app/utilities/paginationState';

interface SearchResult {
  audits: Audits[];
  total: number;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(audits: Audits[], column: string, direction: string): Audits[] {
  if (direction === '') {
    return audits;
  } else {
    return [...audits].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(audit: Audits, term: string, pipe: PipeTransform) {
  return audit.name.toLowerCase().includes(term.toLowerCase())
    || pipe.transform(audit.id).includes(term);

}

@Component({
  selector: 'app-listaudits',
  templateUrl: './listaudits.component.html',
  styleUrls: ['./listaudits.component.css'],
  providers: [AuditsService, DecimalPipe]

})
export class ListauditsComponent implements OnInit {

  audits$: BehaviorSubject<Audits[]> = new BehaviorSubject<Audits[]>([]);
  total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true) ;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;


  private _search$ = new Subject<void>();
  private activeAudits: Audits[] = [];


   state: PaginationState = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  constructor(
    public auditservice: AuditsService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private pipe: DecimalPipe) {
      this.loadaudits();
   }

  sortedaudits: Audits[];

  private loadaudits() {
    this.SpinnerService.show();
    this.auditservice.getActiveAudits().subscribe((res) => {
      this.SpinnerService.hide();
      this.activeAudits = res;

      this._search$.pipe(
        tap(() => this.loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this.loading$.next(false))
      ).subscribe(result => {
        this.audits$.next(result.audits);
        this.total$.next(result.total);
      });

      this._search$.next();
    })
      // tslint:disable-next-line: no-unused-expression
      , (err: any) => {
        this.SpinnerService.hide();
        console.log(err);
      };
  }

  ngOnInit(): void {

  }

  get page() { return this.state.page; }
  get pageSize() { return this.state.pageSize; }

  // tslint:disable-next-line: adjacent-overload-signatures
  set page(page: number) { this._set({page}); }
  // tslint:disable-next-line: adjacent-overload-signatures
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set searchTerm(searchTerm: string) { this._set({searchTerm}); }
  set sortColumn(sortColumn: string) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

  private _set(patch: Partial<PaginationState>) {
    Object.assign(this.state, patch);
    this._search$.next();
  }


  private _search(): Observable<SearchResult> {

    const {sortColumn, sortDirection, pageSize, page, searchTerm} = this.state;
    // 1. sort
    let audits  = sort(this.activeAudits, sortColumn, sortDirection);

    // 2. filter
    audits = audits.filter(x => matches(x, searchTerm, this.pipe));
    const total = audits.length;

    // 3. paginate
    audits = audits.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

    return of({audits, total});

  }

  addNewAudit(): void{
    this.router.navigateByUrl('newaudit');
  }

  editAudit(e): void{
    window.localStorage.removeItem('auditId');
    window.localStorage.setItem('auditId', e.id.toString());
    this.router.navigate(['editaudit']);
  }

  deleteAudit(audit: Audits): void{
    if (confirm('Are you sure to delete ' + audit.name + '?')) {
        this.SpinnerService.show();
        this.auditservice.delete(audit).subscribe((res) => {
          this.loadaudits();
          this.SpinnerService.hide();
          console.log('Audits created successfully!');
          this.sortedaudits = res;
        });
    }
  }

  getAuditDetails(audit: Audits)
  {
    window.localStorage.removeItem('auditId');
    window.localStorage.setItem('auditId', audit.id.toString());

    this.router.navigateByUrl('auditreeview');
  }

  onSort({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;
  }



}
