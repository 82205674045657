import { Component, QueryList, ViewChildren, OnInit , PipeTransform  } from '@angular/core';
import {Observable , BehaviorSubject , Subject , of } from 'rxjs';
import {NgbdSortableHeader, SortEvent} from 'src/app/sortable.directive';
import { Router } from '@angular/router';
import { Audits } from 'src/app/models/audits';
import { AuditReports } from 'src/app/models/audit-reports';
import { AuditsService } from 'src/app/services/audits.service';
import { AuditreportsService } from 'src/app/services/auditreports.service';
import {DecimalPipe} from '@angular/common';
import {SortDirection } from 'src/app/sortable.directive';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {PaginationState } from 'src/app/utilities/paginationState';
import { NgxSpinnerService } from 'ngx-spinner';

interface SearchResult {
  auditReports: AuditReports[];
  total: number;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(auditReports: AuditReports[], column: string, direction: string): AuditReports[] {
  if (direction === '') {
    return auditReports;
  } else {
    return [...auditReports].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(auditReports: AuditReports, term: string, pipe: PipeTransform) {
  return auditReports.name.toLowerCase().includes(term.toLowerCase())
    || pipe.transform(auditReports.id).includes(term);

}


@Component({
  selector: 'app-listauditreports',
  templateUrl: './listauditreports.component.html',
  styleUrls: ['./listauditreports.component.css']
})
export class ListauditreportsComponent implements OnInit {

  auditReports$: BehaviorSubject<AuditReports[]> = new BehaviorSubject<AuditReports[]>([]);
  total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true) ;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;


  private _search$ = new Subject<void>();
  activeAuditReports: AuditReports[] = [];


   state: PaginationState = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };


  constructor( public auditreportsService : AuditreportsService ,  private router: Router , private pipe: DecimalPipe ,  private SpinnerService: NgxSpinnerService)
  {
      this.loadAuditReports() ;
  }

  ngOnInit(): void {
  }

  get page() { return this.state.page; }
  get pageSize() { return this.state.pageSize; }

  // tslint:disable-next-line: adjacent-overload-signatures
  set page(page: number) { this._set({page}); }
  // tslint:disable-next-line: adjacent-overload-signatures
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set searchTerm(searchTerm: string) { this._set({searchTerm}); }
  set sortColumn(sortColumn: string) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

  private _set(patch: Partial<PaginationState>) {
    Object.assign(this.state, patch);
    this._search$.next();
  }


  private _search(): Observable<SearchResult> {

    const {sortColumn, sortDirection, pageSize, page, searchTerm} = this.state;
    // 1. sort
    let auditReports  = sort(this.activeAuditReports, sortColumn, sortDirection);

    // 2. filter
    auditReports = auditReports.filter(x => matches(x, searchTerm, this.pipe));
    const total = auditReports.length;

    // 3. paginate
    auditReports = auditReports.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

    return of({auditReports, total});

  }


  private loadAuditReports() {

    this.SpinnerService.show();
    this.auditreportsService.getActiveAuditReports().subscribe((res) => {
      this.SpinnerService.hide();
      this.activeAuditReports = res,

      this._search$.pipe(
        tap(() => this.loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this.loading$.next(false))
      ).subscribe(result => {
        this.auditReports$.next(result.auditReports);
        this.total$.next(result.total);
      });

      this._search$.next();
    })
      // tslint:disable-next-line: no-unused-expression
      , (err: any) => {
        this.SpinnerService.hide();
        console.log(err);
      };
    }

    onSort({column, direction}: SortEvent) {

      this.headers.forEach(header => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });

      this.sortColumn = column;
      this.sortDirection = direction;
    }

}
