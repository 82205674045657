

<div class = "row">
  <label>  {{stepWithOptions.id}} . {{stepWithOptions.question}}</label>
</div>

<div class="form-check" *ngFor="let a of stepWithOptions.stepSelectOptions">
  <label for="a.text">
    <input type="radio"  name="{{stepWithOptions.question}}"  value="{{a.id}}" [checked]="selectedAnswer == a.id"  (change)="changeAnswer(a)">
    {{a.text}}
  </label>
</div>

