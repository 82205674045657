<div class="container">
  <form #newUserForm="ngForm" autocomplete="off">
    <input type="hidden" name="employeeId" [(ngModel)]='user.employeeId'>
    <input type="hidden" name="aspNetUserId" [(ngModel)]='user.aspNetUserId'>
    <div class="form-group">
      <label for="FirstName">First Name</label>
      <input type="text" class="form-control" name="FirstName" [(ngModel)]='user.firstName' size="100" disabled>
    </div>
    <div class="form-group">
      <label for="LastName">Last Name</label>
      <input type="text" class="form-control" name="LastName" [(ngModel)]='user.lastName' size="100" disabled>
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input type="text" class="form-control" name="email" [(ngModel)]='user.email' size="100" [readonly]="true">
    </div>
    <div class="form-group">
      <label for="username">Username</label>
      <input type="text" class="form-control" name="username" [(ngModel)]='user.userName' size="100" [readonly]="true">
    </div>
    <div class="form-group" *ngIf="!isemployeeaudituser">
      <label for="Password">Password</label>
      <input type="password" class="form-control" name="Password"  required minlength="8"  [(ngModel)]='user.password' size="100">
    </div>

    <div class="form-group" *ngIf="!isemployeeaudituser">
      <label for="ConfirmPassword">Confirm Password</label>
      <input type="password" class="form-control" name="ConfirmPassword" required minlength="8"  size="100">
    </div>

    <div class="form-group" *ngIf="!isemployeeaudituser">
      <label for="role">User Role</label>
      <div>
        <select id="role" name="role" [(ngModel)]='user.role' >
          <option *ngFor="let r of userRoles | keyvalue" [value]="r['key']">{{r['value']}}</option>
        </select>
      </div>
    </div>
    <div class="form-group" *ngIf="!isemployeeaudituser">
      <input type="button" value="Add Audit Pro Login" (click)="save(newUserForm.value)">
      <!-- <input type="button" value="Add Audit Pro Login" (click)="openaddauditprologin()"> -->
    </div>
  </form>
  </div>

  <ng-template #addauditprologinmodal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4  class="modal-title">Add Audit Pro Login</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="email">Email</label>
        <input type="text" class="form-control" name="email" [(ngModel)]='user.email' size="100" >
      </div>
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" class="form-control" name="username" [(ngModel)]='user.userName' size="100" >
      </div>
      <div class="form-group">
        <label for="Password">Password</label>
        <input type="password" class="form-control" name="Password" [(ngModel)]='user.password' size="100">
      </div>

      <div class="form-group">
        <label for="ConfirmPassword">Confirm Password</label>
        <input type="password" class="form-control" name="ConfirmPassword"  size="100">
      </div>

      <div class="form-group">
        <label for="role">User Role</label>
        <div>
          <select id="role" name="role" [(ngModel)]='user.role' >
            <option *ngFor="let r of userRoles | keyvalue" [value]="r['key']">{{r['value']}}</option>
          </select>
        </div>
      </div>
      <input type="button" value="Save" (click)="save(newUserForm.value)">
    </div>
    <div class="modal-footer">
        <!-- <button type="button" id="cancel-edit-btn" class="btn btn-primary" (click)="c('Close click')">Cancel</button> -->
    </div>
  </ng-template>

  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Loading. </p>
  </ngx-spinner>
