
import { Component, Directive, EventEmitter, Input, Output, QueryList, ViewChildren, OnInit, ElementRef, ViewChild , PipeTransform } from '@angular/core';
import {Observable , BehaviorSubject , Subject , of, observable } from 'rxjs';
import { StepService } from 'src/app/services/step.service';
import { Steps } from 'src/app/models/steps';
import { Router } from '@angular/router';
import {NgbdSortableHeader, SortEvent} from 'src/app/sortable.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {DecimalPipe} from '@angular/common';
import {SortDirection } from 'src/app/sortable.directive';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {PaginationState } from 'src/app/utilities/paginationState';



@Component({
  selector: 'app-answersteps',
  templateUrl: './answersteps.component.html',
  styleUrls: ['./answersteps.component.css']
})
export class AnswerstepsComponent implements OnInit {
   @Input() steps: Steps[] = [];
   @Output() stepWithObservations : EventEmitter<Steps[]> = new EventEmitter<Steps[]>();

   AnsweredSteps :Steps[] = [] ;

   constructor(){}

   ngOnInit(): void {}

   ngOnChanges(): void{ }

   collectObservations(event: Steps)
   {
     if(this.AnsweredSteps.length >  0 )
     {
       if(this.AnsweredSteps.includes(event))
       {
        let index : number = this.AnsweredSteps.indexOf(event);
        this.AnsweredSteps.splice(index,1) ;
       }
        this.AnsweredSteps.push(event);
     }
     else
     {
      this.AnsweredSteps.push(event);
     }

   }

   sendObservations()
   {
      this.stepWithObservations.emit(this.AnsweredSteps);
   }



}
