import { Component, OnInit } from '@angular/core';
import { Employee, EmployeeRole, EmployeeRoleToLabel } from 'src/app/models/employee';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {

  user: Employee  = {} as Employee;

  userRoles = EmployeeRoleToLabel;
  public EmployeeRoleToLabel = EmployeeRoleToLabel;

  constructor(private userService: UserService,
              private router: Router,
              private modalService: NgbModal,
              private SpinnerService: NgxSpinnerService) {
              }

  ngOnInit(): void {
  }

  save(user: Employee): void{
    debugger;
    this.userService.create(user);
  }
}
