import { Component, OnInit, Input } from '@angular/core';
import { Inspections } from 'src/app/models/inspections';
import { InspectionService } from 'src/app/services/inspection.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-subsectioninspections',
  templateUrl: './subsectioninspections.component.html',
  styleUrls: ['./subsectioninspections.component.css']
})
export class SubsectioninspectionsComponent implements OnInit {
  inspections: Inspections[];

  // tslint:disable-next-line: no-input-rename
   @Input() subsectionId: number;

   constructor(private inspectionService: InspectionService,
               private SpinnerService: NgxSpinnerService) {
    }

   ngOnInit(): void {
     this.loadinspections();
   }

   inspectionCreated(t: any){
     this.SpinnerService.show();
     if (t && t.inspectionId)
     {
       this.inspectionService.createsubsectioninspection(this.subsectionId, t.inspectionId).subscribe((res) => {
           this.SpinnerService.hide();
           this.loadinspections();
         })
           // tslint:disable-next-line: no-unused-expression
           , (err: any) => {
             this.SpinnerService.hide();
             console.log(err);
           };
     }
   }

   inspectionUpdated(t: any){
     this.loadinspections();
   }

   inspectionDeleted(t: any): void{
     this.SpinnerService.show();
     this.inspectionService.deletesubsectioninspection(this.subsectionId, t.inspectionId).subscribe((res) => {
         console.log('Sub Section Inspection deleted successfully!');
         this.inspections = res;
         this.loadinspections();
         this.SpinnerService.hide();
       });
   }

   private loadinspections() {
    if (!(this.subsectionId) || this.subsectionId === 0)
     {
       const subsectionId = window.localStorage.getItem('subsectionId');
       if (!subsectionId) {
         return;
       }
       else
       {
         this.subsectionId = Number(subsectionId);
       }

     }
    this.SpinnerService.show();
    this.inspectionService.getsubsectioninspections(this.subsectionId).subscribe((res) => {
         this.SpinnerService.hide();
         this.inspections = res;
       })
         // tslint:disable-next-line: no-unused-expression
         , (err: any) => {
           this.SpinnerService.hide();
           console.log(err);
         };
       }
}
