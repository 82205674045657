import { Component, OnInit, PipeTransform, Input, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Employee } from 'src/app/models/employee';
import { NgbModalRef, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader, SortDirection, SortEvent } from 'src/app/sortable.directive';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { PaginationState } from 'src/app/utilities/paginationState';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DecimalPipe } from '@angular/common';
import { tap, debounceTime, switchMap, delay } from 'rxjs/operators';

interface SearchResult {
  employees: Employee[];
  total: number;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(employees: Employee[], column: string, direction: string): Employee[] {
  if (direction === '') {
    return employees;
  } else {
    return [...employees].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(employee: Employee, term: string, pipe: PipeTransform) {
  return employee.firstName.toLowerCase().includes(term.toLowerCase())
    || pipe.transform(employee.employeeId).includes(term);
}

@Component({
  selector: 'app-listusers',
  templateUrl: './listusers.component.html',
  styleUrls: ['./listusers.component.css']
})
export class ListusersComponent implements OnInit {

// tslint:disable-next-line: no-input-rename
@Input() employees: Employee[] = [];

modalRef: NgbModalRef;

@ViewChild('updateUserModal') updateUserModal: ElementRef;
@ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

employees$: BehaviorSubject<Employee[]> = new BehaviorSubject<Employee[]>([]);
total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true) ;


// tslint:disable-next-line: variable-name
private _search$ = new Subject<void>();

state: PaginationState = {
  page: 1,
  pageSize: 10,
  searchTerm: '',
  sortColumn: '',
  sortDirection: ''
};

constructor(private userservice: UserService,
            private SpinnerService: NgxSpinnerService,
            private modalService: NgbModal,
            private pipe: DecimalPipe) {
 }

ngOnInit(): void {
  this.loadusers();
}

// tslint:disable-next-line: use-lifecycle-interface
ngOnChanges(): void{

    this._search$.pipe(
      tap(() => this.loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this.loading$.next(false))
    ).subscribe(result => {
      this.employees$.next(result.employees);
      this.total$.next(result.total);
    });

    this._search$.next();
}

private loadusers() {

    this.SpinnerService.show();

    this.userservice.getActiveUsers().subscribe((res) => {
      this.SpinnerService.hide();
      this.employees = res;

      this._search$.pipe(
        tap(() => this.loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this.loading$.next(false))
      ).subscribe(result => {
        this.employees$.next(result.employees);
        this.total$.next(result.total);
      });

      this._search$.next();
    })
      // tslint:disable-next-line: no-unused-expression
      , (err: any) => {
        this.SpinnerService.hide();
        console.log(err);
      };
}

get page() { return this.state.page; }
get pageSize() { return this.state.pageSize; }

// tslint:disable-next-line: adjacent-overload-signatures
set page(page: number) { this._set({page}); }
// tslint:disable-next-line: adjacent-overload-signatures
set pageSize(pageSize: number) { this._set({pageSize}); }
set searchTerm(searchTerm: string) { this._set({searchTerm}); }
set sortColumn(sortColumn: string) { this._set({sortColumn}); }
set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

private _set(patch: Partial<PaginationState>) {
  Object.assign(this.state, patch);
  this._search$.next();
}


private _search(): Observable<SearchResult> {

  const {sortColumn, sortDirection, pageSize, page, searchTerm} = this.state;
  // 1. sort
  let employees  = sort(this.employees, sortColumn, sortDirection);

  // 2. filter
  if (employees)
  {
    employees = employees.filter(x => matches(x, searchTerm, this.pipe));
    const total = employees.length;
    // 3. paginate
    employees = employees.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

    return of({employees, total});
  }
  return of({employees, total: 0});
}

onSort({column, direction}: SortEvent) {

  // resetting other headers
  this.headers.forEach(header => {
    if (header.sortable !== column) {
      header.direction = '';
    }
  });

  this.sortColumn = column;
  this.sortDirection = direction;
}

save(user: Employee): void{
  this.userservice.create(user);
}

addUser(): void{
  // this.modalService.open(this.a, { size: 'xl', backdrop: 'static'});
}

editUser(e): void{
  window.localStorage.removeItem('employeeId');
  window.localStorage.setItem('employeeId', e.employeeId.toString());
  this.modalService.open(this.updateUserModal, { size: 'xl', backdrop: 'static'});
}
}
