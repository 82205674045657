import { Component, OnInit ,Input ,Output,EventEmitter} from '@angular/core';
import { StepService } from 'src/app/services/step.service';
import { Steps } from 'src/app/models/steps';


@Component({
  selector: 'app-yesnoanswers',
  templateUrl: './yesnoanswers.component.html',
  styleUrls: ['./yesnoanswers.component.css']
})
export class YesnoanswersComponent implements OnInit {

  @Input() step: Steps;
  @Output() stepWithObservations : EventEmitter<Steps> = new EventEmitter<Steps>();
  stepWithOptions: Steps;
  selectedAnswer:any;

  constructor() { }

  ngOnInit(): void {
    this.analyseInput() ;
  }


  analyseInput()
  {
    if(this.step != null )
    {
      this.stepWithOptions = this.step ;

      if(this.stepWithOptions.observations [0] != null  )
      {
        this.selectedAnswer = this.stepWithOptions.observations[0].AnsYesNo;
        
      }
     
    }
  }

  changeAnswer(e)
  {
    debugger ; 
    let selctedOption : any = e.target.defaultValue ;
   
    let observation :any =  ({StepId: this.stepWithOptions.id ,  AnsYesNo : selctedOption });
    this.stepWithOptions.observations =[] ;
    this.stepWithOptions.observations.push(observation);

    this.stepWithObservations.emit(this.stepWithOptions);
  }


}
