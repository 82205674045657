import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Sections } from 'src/app/models/sections';
import { SectionsService } from 'src/app/services/sections.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-categorysections',
  templateUrl: './categorysections.component.html',
  styleUrls: ['./categorysections.component.css']
})
export class CategorysectionsComponent implements OnInit {
  sections: Sections[];

  modalRef: NgbModalRef;

 // tslint:disable-next-line: no-input-rename
  @Input() categoryId: number;

  constructor(private sectionsservice: SectionsService,
              private router: Router,
              private SpinnerService: NgxSpinnerService,
              private modalService: NgbModal) {
   }

  ngOnInit(): void {
    this.loadCategorySections();
  }

  sectioncreated(t: any){
    if (t && t.sectionId)
    {
      this.sectionsservice.createcategorysections(this.categoryId, t.sectionId).subscribe((res) => {
          this.SpinnerService.hide();
          this.loadCategorySections();
        })
          // tslint:disable-next-line: no-unused-expression
          , (err: any) => {
            this.SpinnerService.hide();
            console.log(err);
          };
    }
  }

  sectionupdated(t: any){
    this.loadCategorySections();
  }

  sectiondeleted(t: any): void{
    this.SpinnerService.show();
    this.sectionsservice.deletecategorysection(this.categoryId, t.sectionId).subscribe((res) => {
        console.log('Section deleted successfully!');
        this.loadCategorySections();
        this.SpinnerService.hide();
      });
  }

  private loadCategorySections() {
    const categoryId = window.localStorage.getItem('categoryId');
    if (!categoryId) {
      alert('Invalid action.');
      return;
    }

    this.categoryId = Number(categoryId);
    if (this.categoryId && this.categoryId !== 0)
    {
      this.SpinnerService.show();
      this.sectionsservice.getcategorysections(this.categoryId).subscribe((res) => {
        this.SpinnerService.hide();
        this.sections = res;
      })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.SpinnerService.hide();
          console.log(err);
        };
      }
  }

}
