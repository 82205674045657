import { Component, Directive, EventEmitter, Input, Output, QueryList, ViewChildren, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { StepService } from 'src/app/services/step.service';
import { Steps } from 'src/app/models/steps';

@Component({
  selector: 'app-auditsteps',
  templateUrl: './auditsteps.component.html',
  styleUrls: ['./auditsteps.component.css']
})
export class AuditstepsComponent implements OnInit {

  sortedsteps: Steps[];

 // tslint:disable-next-line: no-input-rename
  @Input() auditid: number;

  constructor(private stepservice: StepService,
              private SpinnerService: NgxSpinnerService) {
   }

  ngOnInit(): void {
    this.loadsteps();
  }

  stepCreated(t: any){
    this.SpinnerService.show();
    if (t && t.stepId)
    {
      this.stepservice.createAuditStep(this.auditid, t.stepId).subscribe((res) => {
          this.SpinnerService.hide();
          this.loadsteps();
        })
          // tslint:disable-next-line: no-unused-expression
          , (err: any) => {
            this.SpinnerService.hide();
            console.log(err);
          };
    }
  }

  stepUpdated(t: any){
    this.loadsteps();
  }

  stepDeleted(t: any): void{
    this.SpinnerService.show();
    this.stepservice.deleteauditstep(this.auditid, t.stepId).subscribe((res) => {
        console.log('Step deleted successfully!');
        this.sortedsteps = res;
        this.SpinnerService.hide();
      });
  }

  private loadsteps() {
    if (this.auditid && this.auditid !== 0)
    {
      this.SpinnerService.show();
      this.stepservice.GetAuditSteps(this.auditid).subscribe((res) => {
        this.SpinnerService.hide();
        this.sortedsteps = res;
      })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.SpinnerService.hide();
          console.log(err);
        };
      }
  }
}
