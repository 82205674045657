


 <div class="row">
  <div class="col">
     <div  *ngFor="let a of auditCategories">
      <div class="form-check">
        <label class="form-check-label" for="a.category.name">
         <h4>  Category Steps for {{a.category.name}} </h4>
        </label>

        <app-answersteps  [steps]='a.category.categorySteps'>
        </app-answersteps>


      </div>
    </div>
  </div>
</div>



