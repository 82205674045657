
<div class="container">

  <div class = "row" >
    <button type="button" class="btn btn-sm btn-primary" (click)="backToAuditList()">
      Back To Audit List
    </button>
  </div>

  <div class="row">
    <h3> {{audit.name}} </h3>
  </div>


  <div class="row" *ngIf="auditTree.length > 0">

    <ngx-bootstrap-treeview [isOpened]="true" (leafClicked)="defaultStyleLeafClickedEventHandler()"  [tree]="auditTree[0]">
    </ngx-bootstrap-treeview>

  </div>

  <div class="row" *ngIf="auditTree.length > 0">

    <ngx-bootstrap-treeview [isOpened]="true" (leafClicked)="defaultStyleLeafClickedEventHandler()"  [tree]="auditTree[1]">
    </ngx-bootstrap-treeview>

  </div>

  <!-- <hr>


  <p>
    <button type="button" class="btn btn-outline-primary" (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
      Toggle
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed">
    <div class="row">
      <app-auditsteps [auditid]='auditId'></app-auditsteps>
    </div>
  </div>


  <p>
    <button type="button" class="btn btn-outline-primary" (click)="isCollapsed2 = !isCollapsed2"
            [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample">
      Toggle
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed2">
    <div class="row">
      <app-auditcategories [auditid]='auditId'></app-auditcategories>
    </div>

  </div>
 -->


</div>


