import { Component, OnInit } from '@angular/core';
import { Steps } from 'src/app/models/steps';
import { StepService } from 'src/app/services/step.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-subsectionsteps',
  templateUrl: './subsectionsteps.component.html',
  styleUrls: ['./subsectionsteps.component.css']
})
export class SubsectionstepsComponent implements OnInit {
  sortedsteps: Steps[];

  subsectionId: number;

  constructor(private stepservice: StepService,
              private SpinnerService: NgxSpinnerService) {
   }

  ngOnInit(): void {
   if (!(this.subsectionId) || this.subsectionId === 0)
   {
     const subsectionId = window.localStorage.getItem('subsectionId');
     if (!subsectionId) {
       return;
     }
     else
     {
       this.subsectionId = Number(subsectionId);
     }

   }
   this.loadsteps();
  }

  stepCreated(t: any){
    this.SpinnerService.show();
    if (t && t.stepId)
    {
      this.stepservice.createsubsectionstep(this.subsectionId, t.stepId).subscribe((res) => {
          this.SpinnerService.hide();
          this.loadsteps();
        })
          // tslint:disable-next-line: no-unused-expression
          , (err: any) => {
            this.SpinnerService.hide();
            console.log(err);
          };
    }
  }

  stepUpdated(t: any){
    this.loadsteps();
  }

  stepDeleted(t: any): void{
    this.SpinnerService.show();
    this.stepservice.deletesubsectionstep(this.subsectionId, t.stepId).subscribe((res) => {
        console.log('Step deleted successfully!');
        this.sortedsteps = res;
        this.SpinnerService.hide();
      });
  }

  private loadsteps() {

    this.SpinnerService.show();
    this.stepservice.GetSubSectionSteps(this.subsectionId).subscribe((res) => {
        this.SpinnerService.hide();
        this.sortedsteps = res;
      })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.SpinnerService.hide();
          console.log(err);
        };
    }

}
