import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { CreateauditComponent } from './components/audit/createaudit/createaudit.component';
import { EditauditComponent } from './components/audit/editaudit/editaudit.component';
import { ListauditsComponent } from './components/audit/listaudits/listaudits.component';
import { AuthGuard } from './utilities/auth.guard';
import { CreateStepComponent } from './components/steps/createstep/createstep.component';
import { EditStepComponent } from './components/steps/editstep/editstep.component';
import { ListStepsComponent } from './components/steps/liststeps/liststeps.component';
import {  ListcompaniesComponent} from './components/companies/listcompanies/listcompanies.component';
import { ListusersComponent } from './components/admin/usermangement/listusers/listusers.component';
import { ListinspectionsComponent } from './components/inspection/listinspections/listinspections.component';
import {ListsectionsComponent} from './components/sections/listsections/listsections.component';
import {ListcategoriesComponent} from './components/categories/listcategories/listcategories.component';
import {ListsubsectionsComponent} from './components/subsections/listsubsections/listsubsections.component';
import { ListauditreportsComponent } from './components/auditreports/listauditreports/listauditreports.component';
import { CreateauditreportsComponent } from './components/auditreports/createauditreports/createauditreports.component';
import {AnswerstepsComponent} from './components/observations/answersteps/answersteps.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AuditTreeviewComponent} from './components/audit/audit-treeview/audit-treeview.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },

  { path: 'dashboard', component: DashboardComponent , canActivate: [AuthGuard]},

  { path: 'newaudit', component: CreateauditComponent , canActivate: [AuthGuard]},
  { path: 'editaudit', component: EditauditComponent, canActivate: [AuthGuard] },
  { path: 'listaudits', component: ListauditsComponent, canActivate: [AuthGuard] },

  { path: 'newstep', component: CreateStepComponent , canActivate: [AuthGuard]},
  { path: 'editstep', component: EditStepComponent, canActivate: [AuthGuard] },
  { path: 'liststeps', component: ListStepsComponent, canActivate: [AuthGuard] },

  { path: 'listsections', component: ListsectionsComponent, canActivate: [AuthGuard] },
  { path: 'listcategories', component: ListcategoriesComponent, canActivate: [AuthGuard] },
  { path: 'listsubsections', component: ListsubsectionsComponent, canActivate: [AuthGuard] },

  { path: 'companies', component: ListcompaniesComponent , canActivate: [AuthGuard]},

  { path: 'users', component: ListusersComponent , canActivate: [AuthGuard]},

  { path: 'auditreports', component: ListauditreportsComponent , canActivate: [AuthGuard]},
  { path: 'createreports', component: CreateauditreportsComponent , canActivate: [AuthGuard]},
  { path: 'answersteps', component: AnswerstepsComponent , canActivate: [AuthGuard]},
  { path: 'auditreeview', component: AuditTreeviewComponent , canActivate: [AuthGuard]},
  // { path: 'auditreeview/:audit', component: AuditTreeviewComponent , canActivate: [AuthGuard]},


  { path: 'inspections', component: ListinspectionsComponent , canActivate: [AuthGuard]},

  // {
  //   path: 'personal',
  //   loadChildren: () =>
  //     import('./personal/personal.module').then(m => m.PersonalModule)
  // } ,

  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
