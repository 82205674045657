<div class="container">
  <form #editForm="ngForm">
    <div class="form-group">
      <input type="hidden" name="id" [(ngModel)]='subsection.id'>
      <label for="Name">Name</label>
      <input type="text" class="form-control" name="Name" [(ngModel)]='subsection.name' size="100">
    </div>
    <div calss="card">
      <app-subsectionsteps></app-subsectionsteps>
    </div>
    <div calss="card">
      <app-subsectioninspections></app-subsectioninspections>
    </div>
    <input type="button" class="btn btn-primary" value="Save" (click)="Save(editForm.value)"  ngbTooltip="Save Category Section">
  </form>
 </div>
