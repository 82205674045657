


<div class = "row">
  <label>  {{stepWithOptions.id}} . {{stepWithOptions.question}}</label>
</div>

<div class="form-check">
  <label for="yes">
    <input id="yes" type="radio" [value]="true" name=" {{stepWithOptions.id}}" [checked]="selectedAnswer == 'true'" (change)="changeAnswer($event)">
   Yes
  </label>
</div>
<div class="form-check">
  <label for="no">
    <input id="no" type="radio" [value]="false" name=" {{stepWithOptions.id}}"  [checked]="selectedAnswer == 'false'" (change)="changeAnswer($event)">
   No
  </label>
</div>
