
<div class="container">
  <h1>New Audit</h1>

  <a href="#" routerLink="/audit/listaudits" class="btn btn-primary">Back</a>

  <form [formGroup]="form" (ngSubmit)="submit()">

      <div class="form-group">
          <label for="name">Audit Name:</label>
          <input
              formControlName="Name"
              id="name"
              type="text"
              class="form-control">
          <div *ngIf="f.Name.touched && f.Name.invalid" class="alert alert-danger">
          <div *ngIf="f.Name.errors.required">Name is required.</div>
          </div>
      </div>



      <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Submit</button>
  </form>
</div>
