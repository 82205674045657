import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators , FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { Audits } from 'src/app/models/audits';
import { AuditReports } from 'src/app/models/audit-reports';
import { AuditsService } from 'src/app/services/audits.service';
import { CompanyService } from 'src/app/services/company.service';
import { AuditreportsService } from 'src/app/services/auditreports.service';
import { Company } from 'src/app/models/company';
import { Steps } from 'src/app/models/steps';




@Component({
  selector: 'app-createauditreports',
  templateUrl: './createauditreports.component.html',
  styleUrls: ['./createauditreports.component.css']
})
export class CreateauditreportsComponent implements OnInit {

  active = 1;
  form: FormGroup;
  activeAudits: Audits[];
  parentCompanies: Company[] = [];
  childCompanies: Company[] = [] ;
  companyId: number = 0 ;
  branchId: number = 0 ;
  selectedAuditId: number = 0 ;
  selectedCompanyId :any  = 0 ;
  selectedBranchId:any  = 0 ;
  selectedAudit: any = {};
  auditReportName : string ="" ;
  auditSteps: any[] = [];

  auditReports : any ;

  constructor(public auditsService: AuditsService,
    public companyService: CompanyService,
    public auditreportsService : AuditreportsService ,
    private router: Router,
    private SpinnerService: NgxSpinnerService) {
    this.loadAudits();
    this.loadCompanies();
  }

  ngOnInit(): void {

    this.createForm() ;
  }

  createForm() {
    this.form = new FormGroup({
      txtAuditReportName: new FormControl('') ,
      audits: new FormControl('') ,
      company: new FormControl('') ,
      branch: new FormControl('')

    });
  }

  submit(){

  }

  private loadAudits() {
    this.auditsService.getActiveAudits().subscribe(data =>
      this.activeAudits = data,
      error => console.log(error),
      () => console.log('Get all complete'));
   }

  private loadCompanies() {
    this.companyService.getParentCompanies().subscribe(data =>
      this.parentCompanies = data,
      error => console.log(error),
      () => console.log('Get all complete'));
   }

  onSubmit() {

  }

  selectAudit()
  {
    this.loadAuditDetails();

  }

  selectCompany()
  {
    this.companyId = this.selectedCompanyId;
    this.companyService.getBranches(this.companyId).subscribe(data =>
      this.childCompanies = data,
      error => console.log(error),
      () => console.log("Get company branches completed. ")) ;

  }

  selectBranch()
  {
    this.branchId = this.selectedBranchId ;
  }

  next(){
    this.active =  this.active + 1 ;
  }

  previous()
  {

    this.active = this.active - 1 ;
  }

  loadAuditDetails()
  {
    if (this.selectedAuditId && this.selectedAuditId !== 0)
    {
      this.SpinnerService.show();
        this.auditsService.getAuditDetailsForReport(this.selectedAuditId).subscribe((res) => {
          this.selectedAudit = res;
          this.SpinnerService.hide();
        }),(err: any) => {
          console.log(err);
        };
      }
  }

  collectAuditObservations(event)
  {
    for( let i = 0 ; i< this.selectedAudit.auditSteps.length ; i++ )
    {
      let id : number = this.selectedAudit.auditSteps[i].id ;
      let stepItem : Steps = event.auditSteps.find(element=> element.id === id );
      if(stepItem != null )
      {
        this.selectedAudit.auditSteps[i].step = stepItem ;
      }
    }

  }

  submitObservations()
  {
    debugger ;

    this.auditReports = {name : this.auditReportName , auditId : this.selectedAuditId,  audit : this.selectedAudit ,
     companyId : this.selectedCompanyId , branchId : this.selectedBranchId } ;

    let result : number  = 0 ;
    debugger;
    this.auditreportsService.create(this.auditReports).subscribe((res)=> {
      this.SpinnerService.show();
      result = res;
      this.SpinnerService.hide();
      if(result > 0 )
      {
        alert("Audit Report is successfully created. ") ;
      }
      else{
        alert("result is : " + result) ;
      }

    }), (err : any) => { console.log(err) ; } ;


  }
}
