<div class="container">
  <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="form-group">
          <input formControlName="id" type="hidden">
          <label for="name">Category Name:</label>
          <input
              formControlName="name"
              id="name"
              type="text"
              class="form-control">
          <br>
          <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Save</button>
      </div>
      <hr>
      <app-categorysteps  [categoryId]="this.category.id"></app-categorysteps>
      <hr>
      <app-categoryinspections [categoryId]="this.category.id"></app-categoryinspections>
      <hr>
      <app-categorysections [categoryId]="this.category.id"></app-categorysections>
      <hr>

  </form>
</div>
