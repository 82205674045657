import { Component, OnInit, Input } from '@angular/core';
import { Steps } from 'src/app/models/steps';
import { StepService } from 'src/app/services/step.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sectionsteps',
  templateUrl: './sectionsteps.component.html',
  styleUrls: ['./sectionsteps.component.css']
})
export class SectionstepsComponent implements OnInit {
   sortedsteps: Steps[];

   sectionId: number;

   constructor(private stepservice: StepService,
               private SpinnerService: NgxSpinnerService) {
    }

   ngOnInit(): void {
    if (!(this.sectionId) || this.sectionId === 0)
    {
      const sectionId = window.localStorage.getItem('sectionId');
      if (!sectionId) {
        return;
      }
      else
      {
        this.sectionId = Number(sectionId);
      }

    }
    this.loadsteps();
   }

   stepCreated(t: any){
     this.SpinnerService.show();
     if (t && t.stepId)
     {
       this.stepservice.createsectionstep(this.sectionId, t.stepId).subscribe((res) => {
           this.SpinnerService.hide();
           this.loadsteps();
         })
           // tslint:disable-next-line: no-unused-expression
           , (err: any) => {
             this.SpinnerService.hide();
             console.log(err);
           };
     }
   }

   stepUpdated(t: any){
     this.loadsteps();
   }

   stepDeleted(t: any): void{
     this.SpinnerService.show();
     this.stepservice.deletesectionstep(this.sectionId, t.stepId).subscribe((res) => {
         console.log('Step deleted successfully!');
         this.sortedsteps = res;
         this.SpinnerService.hide();
       });
   }

   private loadsteps() {

     this.SpinnerService.show();
     this.stepservice.GetSectionSteps(this.sectionId).subscribe((res) => {
         this.SpinnerService.hide();
         this.sortedsteps = res;
       })
         // tslint:disable-next-line: no-unused-expression
         , (err: any) => {
           this.SpinnerService.hide();
           console.log(err);
         };
     }

}
