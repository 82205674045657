import { Injectable  , PipeTransform} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Steps } from '../models/steps';
import { environment } from '../../environments/environment';
import { Observable, throwError  , BehaviorSubject  , Subject  , of } from 'rxjs';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {DecimalPipe} from '@angular/common';
import { catchError } from 'rxjs/operators';
import { Company } from '../models/company';
import {SortDirection } from 'src/app/sortable.directive';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {


  constructor(private http: HttpClient ,  private pipe: DecimalPipe ,  private SpinnerService: NgxSpinnerService) {}

  getActiveCompanies(): Observable<Company[]>{
    return this.http.get<Company[]>(environment.safetyhiveUrl + 'api/Companies/' );
  }

  getBranches(parentCompanyId: any): Observable<Company[]>{
    return this.http.get<Company[]>(environment.safetyhiveUrl + 'api/CompanyBranch/GetCompanyBranch?parentCompanyId=' + parentCompanyId);
  }

  getParentCompanies(): Observable<Company[]>
  {
    return this.http.get<Company[]>(environment.safetyhiveUrl + 'api/Companies/ParentCompanies');
  }
}
