import { Component, OnInit, Input } from '@angular/core';
import { SubSections } from 'src/app/models/sub-sections';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubsectionsService } from 'src/app/services/subsections.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sectionsubsections',
  templateUrl: './sectionsubsections.component.html',
  styleUrls: ['./sectionsubsections.component.css']
})
export class SectionsubsectionsComponent implements OnInit {
  subsections: SubSections[];

  modalRef: NgbModalRef;

 // tslint:disable-next-line: no-input-rename
  @Input() sectionId: number;

  constructor(private subsectionsService: SubsectionsService,
              private router: Router,
              private SpinnerService: NgxSpinnerService,
              private modalService: NgbModal) {
   }

  ngOnInit(): void {
    this.loadsectionsubsections();
  }

  subsectioncreated(t: any){
    debugger;
    if (t && t.subsectionId)
    {
      this.subsectionsService.createsectionssubsection(this.sectionId, t.subsectionId).subscribe((res) => {
          this.SpinnerService.hide();
          this.loadsectionsubsections();
        })
          // tslint:disable-next-line: no-unused-expression
          , (err: any) => {
            this.SpinnerService.hide();
            console.log(err);
          };
    }
  }

  subsectionupdated(t: any){
    this.loadsectionsubsections();
  }

  subsectiondeleted(t: any): void{
    debugger;
    this.SpinnerService.show();
    this.subsectionsService.deletesectionsubsection(this.sectionId, t.subsectionId).subscribe((res) => {
        console.log('Sub Section deleted successfully!');
        this.loadsectionsubsections();
        this.SpinnerService.hide();
      });
  }

  private loadsectionsubsections() {
    const sectionId = window.localStorage.getItem('sectionId');
    if (!sectionId) {
      alert('Invalid action.');
      return;
    }

    this.sectionId = Number(sectionId);
    if (this.sectionId && this.sectionId !== 0)
    {
      this.SpinnerService.show();
      this.subsectionsService.getsectionsubsections(this.sectionId).subscribe((res) => {
        this.SpinnerService.hide();
        this.subsections = res;
      })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.SpinnerService.hide();
          console.log(err);
        };
      }
  }

}
