

  <div class="card m-3">
    <h5 class="card-header">Create an Audit Report</h5>
    <div class="card-body">
      <form [formGroup]="form">   <!--ngSubmit)="submit()"  -->
        <div *ngIf ="active == 1">

          <div class="form-group">
            <label for="AuditReportName">Audit Report Name</label>
            <input type="text" class="form-control" formControlName="txtAuditReportName" id="AuditReportName"  [(ngModel)]="auditReportName"   >
          </div>

          <div class="form-group">
            <label>Audit</label>
            <select  class="form-control" formControlName="audits"  [(ngModel)]="selectedAuditId" (change)="selectAudit()">
              <option value="0">Please select Audit</option>
              <option *ngFor="let a of activeAudits" [ngValue]="a.id">{{a.name}}</option>
            </select>
          </div>

          <div class="form-group">
            <label>Company</label>
            <select  class="form-control" formControlName="company" [(ngModel)]="selectedCompanyId" (change)="selectCompany()">
              <option value="0">Please select Company</option>
              <option *ngFor="let c of parentCompanies" [ngValue]="c.companyId">{{c.companyName}}</option>
            </select>
          </div>

          <div class="form-group">
            <label>Branch</label>
            <select class="form-control" formControlName="branch" [(ngModel)]="selectedBranchId" (change)="selectBranch()">
              <option value="0">Please select a Branch</option>
              <option *ngFor="let c of childCompanies" [ngValue]="c.companyId">{{c.companyName}}</option>
            </select>
          </div>

          <div class="text-right">
            <button class="btn btn-success mr-1" (click)="next()">Next</button>
          </div>
        </div>
        <div *ngIf ="active == 2">
         
          <app-auditanswersteps  *ngIf="selectedAudit != null"
            [auditid]='selectedAuditId' [audit]='selectedAudit'
            (auditWithObservations)="collectAuditObservations($event)">
          </app-auditanswersteps>
            <div class="text-right">
              <button class="btn btn-success mr-1" (click)="previous()">Previous</button>
              <button class="btn btn-success mr-1" (click)="next()">Next</button>
            </div>
        </div>

        <div *ngIf ="active == 3" >
          <app-categoryanswersteps  [auditid]='selectedAuditId'  [selectedAudit]='selectedAudit'  *ngIf="selectedAudit != null" >

          </app-categoryanswersteps>
            <div class="text-right">
              <button class="btn btn-success mr-1" (click)="previous()">Previous</button>
              <button class="btn btn-primary" (click)="submitObservations()">Submit</button>
            </div>
        </div>



      </form>

    </div>
  </div>





