import { Component, OnInit, Input, EventEmitter, ElementRef, ViewChildren, ViewChild, Output, PipeTransform, QueryList } from '@angular/core';
import { NgbdSortableHeader, SortDirection, SortEvent } from 'src/app/sortable.directive';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { SectionsService } from 'src/app/services/sections.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';
import { Sections } from 'src/app/models/sections';
import { PaginationState } from 'src/app/utilities/paginationState';
import { tap, debounceTime, switchMap, delay } from 'rxjs/operators';

interface SearchResult {
  sections: Sections[];
  total: number;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(sections: Sections[], column: string, direction: string): Sections[] {
  if (direction === '') {
    return sections;
  } else {
    return [...sections].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(section: Sections, term: string, pipe: PipeTransform) {
  return section.name.toLowerCase().includes(term.toLowerCase())
    || pipe.transform(section.id).includes(term);

}

@Component({
  selector: 'app-listsections',
  templateUrl: './listsections.component.html',
  styleUrls: ['./listsections.component.css']
})
export class ListsectionsComponent implements OnInit {
 // tslint:disable-next-line: no-input-rename
 @Input() sections: Sections[] = [];
 @Input() isallsections = false;
 @Output() eventSectionCreated = new EventEmitter();
 @Output() eventSectionUpdated = new EventEmitter();
 @Output() eventSectionDeleted = new EventEmitter();

 modalRef: NgbModalRef;

 @ViewChild('addsectionmodal') addsectionmodal: ElementRef;
 @ViewChild('editsectionmodal') editsectionmodal: ElementRef;
 @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

 sections$: BehaviorSubject<Sections[]> = new BehaviorSubject<Sections[]>([]);
 total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
 loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true) ;


 // tslint:disable-next-line: variable-name
 private _search$ = new Subject<void>();

  state: PaginationState = {
   page: 1,
   pageSize: 10,
   searchTerm: '',
   sortColumn: '',
   sortDirection: ''
 };

 constructor(private sectionsService: SectionsService,
             private router: Router,
             private spinnerService: NgxSpinnerService,
             private modalService: NgbModal,
             private activeModal: NgbActiveModal,
             private pipe: DecimalPipe) {
               if (this.router.url === '/listsections')
               {
                 this.isallsections = true;
               }
  }

 ngOnInit(): void {
  this.loadsections();
 }

 // tslint:disable-next-line: use-lifecycle-interface
 ngOnChanges(): void{

   if (!this.isallsections)
   {
     this._search$.pipe(
       tap(() => this.loading$.next(true)),
       debounceTime(200),
       switchMap(() => this._search()),
       delay(200),
       tap(() => this.loading$.next(false))
     ).subscribe(result => {
       this.sections$.next(result.sections);
       this.total$.next(result.total);
     });

     this._search$.next();
   }
 }

 sectioncreated(t: any){
   if (t && t.sectionId)
   {
       this.eventSectionCreated.emit({ event, sectionId: t.sectionId });
   }
   this.modalRef.close();
   this.loadsections();
 }

 sectionupdated(t: any){
   this.eventSectionUpdated.emit();
   this.modalRef.close();
   this.loadsections();
 }

 private loadsections() {
  if (this.isallsections) {
     this.spinnerService.show();

     this.sectionsService.getactivesections().subscribe((res) => {
       this.spinnerService.hide();
       this.sections = res;

       this._search$.pipe(
         tap(() => this.loading$.next(true)),
         debounceTime(200),
         switchMap(() => this._search()),
         delay(200),
         tap(() => this.loading$.next(false))
       ).subscribe(result => {
         this.sections$.next(result.sections);
         this.total$.next(result.total);
       });

       this._search$.next();
     })
       // tslint:disable-next-line: no-unused-expression
       , (err: any) => {
         this.spinnerService.hide();
         console.log(err);
       };
   }
 }

 addSection(): void{
   this.modalRef = this.modalService.open(this.addsectionmodal, { size: 'md', backdrop: 'static'});
 }

 editSection(e): void{
   window.localStorage.removeItem('sectionId');
   window.localStorage.setItem('sectionId', e.id.toString());
   this.modalRef = this.modalService.open(this.editsectionmodal, { size: 'xl', backdrop: 'static'});
 }

 deleteSection(section: Sections): void{
   if (confirm('Are you sure to delete ' + section.name + '?')) {
     if (this.isallsections)
     {
       this.spinnerService.show();
       this.sectionsService.delete(section.id).subscribe((res) => {
          console.log('Section deleted successfully!');
          this.sections = res;

          this._search$.pipe(
           tap(() => this.loading$.next(true)),
           debounceTime(200),
           switchMap(() => this._search()),
           delay(200),
           tap(() => this.loading$.next(false))
         ).subscribe(result => {
           this.sections$.next(result.sections);
           this.total$.next(result.total);
         });

          this._search$.next();

          this.spinnerService.hide();
        })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.spinnerService.hide();
          console.log(err);
        };
     }

     this.eventSectionDeleted.emit({ event, sectionId: section.id });
   }
 }

 get page() { return this.state.page; }
 get pageSize() { return this.state.pageSize; }

 // tslint:disable-next-line: adjacent-overload-signatures
 set page(page: number) { this._set({page}); }
 // tslint:disable-next-line: adjacent-overload-signatures
 set pageSize(pageSize: number) { this._set({pageSize}); }
 set searchTerm(searchTerm: string) { this._set({searchTerm}); }
 set sortColumn(sortColumn: string) { this._set({sortColumn}); }
 set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

 private _set(patch: Partial<PaginationState>) {
   Object.assign(this.state, patch);
   this._search$.next();
 }


 private _search(): Observable<SearchResult> {

   const {sortColumn, sortDirection, pageSize, page, searchTerm} = this.state;
   // 1. sort
   let sections  = sort(this.sections, sortColumn, sortDirection);

   // 2. filter
   if (sections)
   {
    sections = sections.filter(x => matches(x, searchTerm, this.pipe));
    const total = sections.length;
     // 3. paginate
    sections = sections.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

    return of({sections, total});

   }

   return of({sections, total: 0});

 }

 onSort({column, direction}: SortEvent) {

   // resetting other headers
   this.headers.forEach(header => {
     if (header.sortable !== column) {
       header.direction = '';
     }
   });

   this.sortColumn = column;
   this.sortDirection = direction;
 }
}
