import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Steps } from '../models/steps';
import { StepWithOptions } from '../models/step-with-options';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StepService {

  constructor(private http: HttpClient) { }

  GetActiveSteps(): Observable<Steps[]>{
    return this.http.get<Steps[]>(environment.auditUrl + 'api/steps/')
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  GetAuditSteps(auditId: number): Observable<Steps[]>{
    return this.http.get<Steps[]>(environment.auditUrl + 'api/steps/GetAuditSteps/' + auditId)
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  GetCategorySteps(categoryId: number): Observable<Steps[]>{
    return this.http.get<Steps[]>(environment.auditUrl + 'api/steps/GetCategorySteps/' + categoryId)
            .pipe(map((res: any[]) => {

              if (res) {
                debugger;
                return res;
              } else {
                return null;
              }
            },
            catchError(this.errorHandler)
            ));
  }

  GetSectionSteps(sectionId: number): Observable<Steps[]>{
    return this.http.get<Steps[]>(environment.auditUrl + 'api/steps/GetSectionSteps/' + sectionId)
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  GetSubSectionSteps(subsectionId: number): Observable<Steps[]>{
    return this.http.get<Steps[]>(environment.auditUrl + 'api/steps/GetSubSectionSteps/' + subsectionId)
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  GetInspectionSteps(inspectionId: number): Observable<Steps[]>{
    return this.http.get<Steps[]>(environment.auditUrl + 'api/steps/GetInspectionSteps/' + inspectionId)
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  GetStepDetail(id: number): Observable<StepWithOptions>{
    return this.http.get<StepWithOptions>(environment.auditUrl + 'api/steps/GetStepDetail/' + id).pipe(map((res: StepWithOptions) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  createAuditStep(auditId: number, stepId: number){
    return this.http.post(environment.auditUrl + 'api/steps/CreateAuditStep/' + auditId + '/' + stepId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  CreateCategoryStep(categoryId: number, stepId: number){
    return this.http.post(environment.auditUrl + 'api/steps/CreateCategoryStep/' + categoryId + '/' + stepId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  createsectionstep(sectionId: number, stepId: number){
    return this.http.post(environment.auditUrl + 'api/steps/CreateSectionStep/' + sectionId + '/' + stepId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  createsubsectionstep(subsectionId: number, stepId: number){
    return this.http.post(environment.auditUrl + 'api/steps/CreateSubSectionStep/' + subsectionId + '/' + stepId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  CreateInspectionStep(inspectionId: number, stepId: number){
    return this.http.post(environment.auditUrl + 'api/steps/CreateInspectionStep/' + inspectionId + '/' + stepId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  create(step: StepWithOptions): Observable<Steps>{
    return this.http.post<Steps>(environment.auditUrl + 'api/steps/', step)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(step: StepWithOptions){
    return this.http.put(environment.auditUrl + 'api/steps/', step)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(step: Steps): Observable<Steps[]>{
    return this.http.delete<Steps[]>(environment.auditUrl + 'api/steps/' + step.id)
    .pipe(map((res: Steps[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deleteauditstep(auditId: number, stepId: number): Observable<Steps[]>{
    return this.http.delete<Steps[]>(environment.auditUrl + 'api/steps/DeleteAuditStep/' + auditId + '/' + stepId)
    .pipe(map((res: Steps[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deletecategorystep(categoryId: number, stepId: number): Observable<Steps[]>{
    return this.http.delete<Steps[]>(environment.auditUrl + 'api/steps/DeleteCategoryStep/' + categoryId + '/' + stepId)
    .pipe(map((res: Steps[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deletesectionstep(sectionId: number, stepId: number): Observable<Steps[]>{
    return this.http.delete<Steps[]>(environment.auditUrl + 'api/steps/DeleteSectionStep/' + sectionId + '/' + stepId)
    .pipe(map((res: Steps[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deletesubsectionstep(subsectionId: number, stepId: number): Observable<Steps[]>{
    return this.http.delete<Steps[]>(environment.auditUrl + 'api/steps/DeleteSubSectionStep/' + subsectionId + '/' + stepId)
    .pipe(map((res: Steps[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deleteinspectionstep(inspectionId: number, stepId: number): Observable<Steps[]>{
    return this.http.delete<Steps[]>(environment.auditUrl + 'api/steps/DeleteInspectionStep/' + inspectionId + '/' + stepId)
    .pipe(map((res: Steps[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
}
