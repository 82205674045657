import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { Categories } from 'src/app/models/categories';
import { AuditCategories } from 'src/app/models/audit-categories';
import {CategoryService} from '../../../services/category.service';
import { AuditcategoriesComponent } from '../auditcategories/auditcategories.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-createcategory',
  templateUrl: './createcategory.component.html',
  styleUrls: ['./createcategory.component.css']
})
export class CreatecategoryComponent implements OnInit {
  form: FormGroup;

  @Input() auditId: number;
  @Output() categorycreated = new EventEmitter();

  constructor(public categoryService: CategoryService,
              private router: Router,
              private activeModal: NgbModal,
              private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      Name: new FormControl('', [Validators.required])
    });
  }
  get f(){
    return this.form.controls;
  }

  submit(){
    debugger;
    this.SpinnerService.show();
    // tslint:disable-next-line: prefer-const
    let category: Categories = {} as Categories;
    category = this.form.value;
    this.categoryService.create(category).subscribe(res => {
      console.log('Catogery created successfully!');
      this.categorycreated.emit({ event, categoryId: res as number });
      this.activeModal.dismissAll('Reason');
      this.SpinnerService.hide();
    });
  }

}
