<div class="container">
  <h5>Inspections</h5>
  <div class="card">
  <app-listinspections [isallinspections]="false"
                 [inspections]='inspections'
                 (eventInspectionCreated)="inspectionCreated($event)"
                 (eventInspectionUpdated)="inspectionUpdated($event)"
                 (eventInspectionDeleted)="inspectionDeleted($event)"></app-listinspections>
  </div>
</div>
