import { Component, Directive, EventEmitter, Input, Output, QueryList, ViewChildren, OnInit, ElementRef, ViewChild , PipeTransform } from '@angular/core';
import {Observable , BehaviorSubject , Subject , of, observable } from 'rxjs';
import { StepService } from 'src/app/services/step.service';
import { Steps } from 'src/app/models/steps';
import { Router } from '@angular/router';
import {NgbdSortableHeader, SortEvent} from 'src/app/sortable.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {DecimalPipe} from '@angular/common';
import {SortDirection } from 'src/app/sortable.directive';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {PaginationState } from 'src/app/utilities/paginationState';

interface SearchResult {
  steps: Steps[];
  total: number;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(steps: Steps[], column: string, direction: string): Steps[] {
  if (direction === '') {
    return steps;
  } else {
    return [...steps].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(step: Steps, term: string, pipe: PipeTransform) {
  return step.question.toLowerCase().includes(term.toLowerCase())
    || pipe.transform(step.id).includes(term);

}

@Component({
  selector: 'app-liststeps',
  templateUrl: './liststeps.component.html',
  styleUrls: ['./liststeps.component.css']
})
export class ListStepsComponent implements OnInit {

  // tslint:disable-next-line: no-input-rename
  @Input() steps: Steps[] = [];
  @Input() isallsteps = false;
  @Output() eventStepCreated = new EventEmitter();
  @Output() eventStepUpdated = new EventEmitter();
  @Output() eventStepDeleted = new EventEmitter();

  modalRef: NgbModalRef;

  @ViewChild('addStepModal') addStepModal: ElementRef;
  @ViewChild('editStepModal') editStepModal: ElementRef;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  steps$: BehaviorSubject<Steps[]> = new BehaviorSubject<Steps[]>([]);
  total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true) ;


  // tslint:disable-next-line: variable-name
  private _search$ = new Subject<void>();

   state: PaginationState = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  constructor(private stepservice: StepService,
              private router: Router,
              private SpinnerService: NgxSpinnerService,
              private modalService: NgbModal,
              private activeModal: NgbActiveModal,
              private pipe: DecimalPipe) {
                if (this.router.url === '/liststeps')
                {
                  this.isallsteps = true;
                }
   }

  ngOnInit(): void {
    this.loadsteps();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(): void{

    if (!this.isallsteps)
    {
      this._search$.pipe(
        tap(() => this.loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this.loading$.next(false))
      ).subscribe(result => {
        this.steps$.next(result.steps);
        this.total$.next(result.total);
      });

      this._search$.next();
    }
  }

  stepCreated(t: any){
    if (t && t.stepId)
    {
        this.eventStepCreated.emit({ event, stepId: t.stepId });
    }
    this.modalRef.close();
    this.loadsteps();
  }

  stepUpdated(t: any){
    this.eventStepUpdated.emit();
    this.modalRef.close();
    this.loadsteps();
  }

  private loadsteps() {

    if (this.isallsteps) {
      this.SpinnerService.show();

      this.stepservice.GetActiveSteps().subscribe((res) => {
        this.SpinnerService.hide();
        this.steps = res;

        this._search$.pipe(
          tap(() => this.loading$.next(true)),
          debounceTime(200),
          switchMap(() => this._search()),
          delay(200),
          tap(() => this.loading$.next(false))
        ).subscribe(result => {
          this.steps$.next(result.steps);
          this.total$.next(result.total);
        });

        this._search$.next();
      })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.SpinnerService.hide();
          console.log(err);
        };
    }
  }

  addStep(): void{
    this.modalRef = this.modalService.open(this.addStepModal, { size: 'md', backdrop: 'static'});
  }

  editStep(e): void{
    window.localStorage.removeItem('stepId');
    window.localStorage.setItem('stepId', e.id.toString());
    this.modalRef = this.modalService.open(this.editStepModal, { size: 'md', backdrop: 'static'});
  }

  deleteStep(step: Steps): void{
    if (confirm('Are you sure to delete ' + step.question + '?')) {
      if (this.isallsteps)
      {
        this.SpinnerService.show();
        this.stepservice.delete(step).subscribe((res) => {
           console.log('Post deleted successfully!');
           this.steps = res;

           this._search$.pipe(
            tap(() => this.loading$.next(true)),
            debounceTime(200),
            switchMap(() => this._search()),
            delay(200),
            tap(() => this.loading$.next(false))
          ).subscribe(result => {
            this.steps$.next(result.steps);
            this.total$.next(result.total);
          });

           this._search$.next();

           this.SpinnerService.hide();
         })
         // tslint:disable-next-line: no-unused-expression
         , (err: any) => {
           this.SpinnerService.hide();
           console.log(err);
         };
      }

      this.eventStepDeleted.emit({ event, stepId: step.id });
    }
  }

  get page() { return this.state.page; }
  get pageSize() { return this.state.pageSize; }

  // tslint:disable-next-line: adjacent-overload-signatures
  set page(page: number) { this._set({page}); }
  // tslint:disable-next-line: adjacent-overload-signatures
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set searchTerm(searchTerm: string) { this._set({searchTerm}); }
  set sortColumn(sortColumn: string) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

  private _set(patch: Partial<PaginationState>) {
    Object.assign(this.state, patch);
    this._search$.next();
  }


  private _search(): Observable<SearchResult> {

    const {sortColumn, sortDirection, pageSize, page, searchTerm} = this.state;
    // 1. sort
    let steps  = sort(this.steps, sortColumn, sortDirection);

    // 2. filter
    if (steps)
    {
      steps = steps.filter(x => matches(x, searchTerm, this.pipe));
      const total = steps.length;
      // 3. paginate
      steps = steps.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

      return of({steps, total});

    }

    return of({steps, total: 0});

  }

  onSort({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;
  }
}
