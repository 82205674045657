import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SubSections } from 'src/app/models/sub-sections';
import { Router } from '@angular/router';
import { SubsectionsService } from 'src/app/services/subsections.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-editsubsection',
  templateUrl: './editsubsection.component.html',
  styleUrls: ['./editsubsection.component.css']
})
export class EditsubsectionComponent implements OnInit {
  subsection: SubSections = {} as SubSections;

  @Input() categoryId: number;
  @Output() subsectionupdated = new EventEmitter();

  constructor(private router: Router,
              private subsectionsService: SubsectionsService,
              private SpinnerService: NgxSpinnerService) {
               }

  ngOnInit(): void {
    this.loadsubsection();
  }

  loadsubsection(): void{
    const subsectionId = window.localStorage.getItem('subsectionId');
    if (!subsectionId) {
      alert('Invalid action.');
      return;
    }

    this.SpinnerService.show();
    this.subsectionsService.getsubsection(Number(subsectionId)).subscribe( data => {
      this.subsection = data as SubSections;
      this.SpinnerService.hide();
      });
  }

  Save(subSections: SubSections): void {
    this.SpinnerService.show();
    this.subsectionsService.update(subSections).subscribe(res => {
        console.log('Section updated successfully!');
        this.subsectionupdated.emit({ event, subsectionId: subSections.id });
    });
  }
}
