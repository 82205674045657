  <ul class="nav flex-column flex-sm-row nav-tabs">
    <!-- <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
      <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['/listaudits']">Audits</a>
    </li> -->
    <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
      <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['/liststeps']">Steps</a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
      <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['/listcategories']">Categories</a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
      <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['/inspections']">Inspections</a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
      <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['/listsections']">Sections</a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
      <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['/listsubsections']">Sub Sections</a>
    </li>
  </ul>

