

  <form>
  <!-- <div class="form-group form-inline">
    Search: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="searchTerm"/>

  </div> -->


<!-- Component - Audit -->
<h1 class="sh-title-1 text-uppercase">Audits</h1>

<!-- Widget -->
<div class="sh-table-widget-box mt-5">
    <div class="sh-widget-box-header">
        <div class="row">
            <div class="col-lg-3">
                <form>
                    <input class="sh-audit-table-search" type="text" name="searchTerm" [(ngModel)]="searchTerm" />
                    <span class="ml-3" *ngIf="loading$ | async">Loading...</span>
                </form>
            </div>
            <div class="col-lg-9 text-right mt-3 mt-lg-0">
                <button type="button" class="btn sh-lg-green-button" (click)="addNewAudit()">
                    <img src="/assets/images/add-icon.svg" alt="" class="mr-2" />
                    New Audit
                </button>
            </div>
        </div>
    </div>

    <!-- Table -->
    <div class="table-responsive">
        <table class="table sh-audit-table mt-5">
          <thead>
            <tr>
              <th scope="col" sortable="id" (sort)="onSort($event)"  class="sh-title-7">ID</th>
              <th scope="col" sortable="name" (sort)="onSort($event)" class="sh-title-7">Name</th>
              <th scope="col" class="sh-title-7">Created On</th>
              <th scope="col" class="sh-title-7">Modified On</th>
              <th scope="col" width="100px" class="sh-title-7 text-center">Edit</th>
              <th scope="col" width="100px" class="sh-title-7 text-center">Delete</th>
              <th scope="col" width="100px" class="sh-title-7 text-center">Details</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let a of  audits$ | async">
                <td scope="row">{{a.id}}</td>
                <td>{{a.name}}</td>
                <td>{{a.createdDate | date:'yyyy-MM-ddTHH:mm'}}</td>
                <td>{{a.modifiedDate | date:'yyyy-MM-ddTHH:mm'}}</td>
                <td>
                  <button type="button" class="btn sh-sm-blue-button" (click)="editAudit(a)" ngbTooltip="Edit Audit">Edit</button>
                </td>
                <td>
                <button type="button" class="btn sh-sm-red-button" (click)="deleteAudit(a)" ngbTooltip="Delete Audit">Delete</button>
                </td>
                <td>
                  <button type="button" class="btn sh-sm-green-button" (click)="getAuditDetails(a)" >Details</button>
                  </td>
              </tr>
          </tbody>
        </table>
    </div>
    <!-- /Table -->

</div>
<!-- /Widget -->
<!-- Component - Audit -->


  <!-- <div class="table-responsive"> -->

  <!-- <table class="table table-striped table-bordered table-sm"> -->
    <!-- <table class="table sh-audit-table">
      <thead>
        <tr>
          <td colspan="6"> <button type="button" class="btn btn-default btn-primary" (click)="addNewAudit()" ngbTooltip="Add new Audit">
            <span class="glyphicon glyphicon-plus"></span> +
            </button> </td>
        </tr>
        <tr>
          <th scope="col" sortable="id" (sort)="onSort($event)" >ID</th>
          <th scope="col" sortable="name" (sort)="onSort($event)">Name</th>
          <th scope="col" >Created On</th>
          <th scope="col" >Modified On</th>
          <th scope="col">Edit</th>
          <th scope="col">Delete</th>
          <th scope="col">Details</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of  audits$ | async">
          <td scope="row">{{a.id}}</td>
          <td>{{a.name}}</td>
          <td>{{a.createdDate | date:'yyyy-MM-ddTHH:mm'}}</td>
          <td>{{a.modifiedDate | date:'yyyy-MM-ddTHH:mm'}}</td>
          <td>
            <button type="button" class="btn btn-default btn-primary" (click)="editAudit(a)" ngbTooltip="Edit Audit">
            <span class="glyphicon glyphicon-edit"></span> Edit
            </button>
          </td>
          <td>
            <button type="button" class="btn btn-default btn-danger" (click)="deleteAudit(a)" ngbTooltip="Delete Audit">
              <span class="glyphicon glyphicon-trash"></span> Delete
          </button>
        </td>
        <td>
          <button type="button" class="btn btn-default btn-success" (click)="getAuditDetails(a)" ngbTooltip="Details">
            <span class="glyphicon glyphicon-search"></span> Details
        </button>
      </td>
        </tr>
      </tbody>
    </table> -->


  <!-- </div> -->

  <div class="d-flex p-2 float-right mt-3">
    <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="pageSize">
      <option [ngValue]="5">5 items per page</option>
      <option [ngValue]="10">10 items per page</option>
      <option [ngValue]="20">20 items per page</option>
      <option [ngValue]="50">50 items per page</option>
    </select>

    <ngb-pagination
      [collectionSize]="(total$ | async)!" [(page)]="page" [pageSize]="pageSize" [maxSize]="10" class="sh-pagination">
    </ngb-pagination>

  </div>

</form>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Loading. </p>
</ngx-spinner>
