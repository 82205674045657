import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Inspections } from 'src/app/models/inspections';
import { Router } from '@angular/router';
import { InspectionService } from 'src/app/services/inspection.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-createinspection',
  templateUrl: './createinspection.component.html',
  styleUrls: ['./createinspection.component.css']
})
export class CreateinspectionComponent implements OnInit {

  inspection: Inspections  = {} as Inspections;
  editForm: any;
  @Output() inspectionCreated = new EventEmitter();

  constructor(private router: Router,
              private inspectionService: InspectionService,
              private SpinnerService: NgxSpinnerService,
              private activeModal: NgbModal) {
               }

  ngOnInit(): void {
  }

  Save(inspections: Inspections): void {
    this.SpinnerService.show();
    this.inspectionService.create(inspections).subscribe(res => {
        inspections = res;
        console.log('Inspection created successfully!');
        this.inspectionCreated.emit({ event, inspectionId: inspections.id });
        // this.activeModal.dismissAll('Reason');
    });
  }

}
