import { Component, OnInit, Input } from '@angular/core';
import { Steps } from 'src/app/models/steps';
import { StepService } from 'src/app/services/step.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-inspectionsteps',
  templateUrl: './inspectionsteps.component.html',
  styleUrls: ['./inspectionsteps.component.css']
})
export class InspectionstepsComponent implements OnInit {

  sortedsteps: Steps[];

 // tslint:disable-next-line: no-input-rename
  inspectionId: number;

  constructor(private stepservice: StepService,
              private SpinnerService: NgxSpinnerService) {
                this.loadsteps();
   }

  ngOnInit(): void {

  }

  stepCreated(t: any){
    this.SpinnerService.show();
    if (t && t.stepId)
    {
      this.stepservice.CreateInspectionStep(this.inspectionId, t.stepId).subscribe((res) => {
          this.SpinnerService.hide();
          this.loadsteps();
        })
          // tslint:disable-next-line: no-unused-expression
          , (err: any) => {
            this.SpinnerService.hide();
            console.log(err);
          };
    }
  }

  stepUpdated(t: any){
    this.loadsteps();
  }

  stepDeleted(t: any): void{
    this.SpinnerService.show();
    this.stepservice.deleteinspectionstep(this.inspectionId, t.stepId).subscribe((res) => {
        console.log('Step deleted successfully!');
        this.sortedsteps = res;
        this.SpinnerService.hide();
      });
  }

  private loadsteps() {
    const id = window.localStorage.getItem('inspectionId');
    if (!id) {
      console.log('No Inspection Id found');
      return;
    }
    this.inspectionId = Number(id);
    if (this.inspectionId && this.inspectionId !== 0)
    {
      this.SpinnerService.show();
      this.stepservice.GetInspectionSteps(this.inspectionId).subscribe((res) => {
        this.SpinnerService.hide();
        this.sortedsteps = res;
      })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.SpinnerService.hide();
          console.log(err);
        };
      }
  }
}
