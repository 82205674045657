import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SubsectionsService } from 'src/app/services/subsections.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubSections } from 'src/app/models/sub-sections';

@Component({
  selector: 'app-createsubsection',
  templateUrl: './createsubsection.component.html',
  styleUrls: ['./createsubsection.component.css']
})
export class CreatesubsectionComponent implements OnInit {
  subSection: SubSections = {} as SubSections;


  @Input() categoryId: number;
  @Output() subsectioncreated = new EventEmitter();

  constructor(private router: Router,
              private subsectionsService: SubsectionsService,
              private SpinnerService: NgxSpinnerService,
              private activeModal: NgbModal) {

               }

  ngOnInit(): void {
  }

  Save(subSection: SubSections): void {
    this.SpinnerService.show();
    this.subsectionsService.create(subSection).subscribe(res => {
        console.log('Section created successfully!');
        debugger;
        this.subsectioncreated.emit({ event, subsectionId: res.id });
    });
  }
}
