<!-- ===== Component - side-bar ===== -->
<nav class="sh-side-bar">

    <!-- Profile link list -->
    <ul class="sh-side-bar-list mb-5">
        <!-- Profile link item -->
        <li class="sh-side-bar-list-item {{IsNavigationOpenDashboard ? 'ico-selected' : ''}}">
            <!-- Side bar navigation link -->
            <a href="#" class="sidebar-icon-link" (click)= "navigationToggle('dashboard')" >
                <img src="/assets/images/growth-icon.svg" alt="" />
            </a>
            <!-- Side bar navigation link -->
        </li>
        <!-- /Profile link item -->
    </ul>
    <!-- /Profile link list -->

    <!-- Side bar links list -->
    <div>
    <ul class="sh-side-bar-list mt-5 pt-5">

        <!-- Side bar links item -->
        <li class="sh-side-bar-list-item  {{IsNavigationOpenAudits ? 'ico-selected' : ''}}"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
            <!-- Side bar navigation link -->
            <a href="#" class="sidebar-icon-link" [routerLink]="['listaudits']"  (click)= "navigationToggle('audits')" >
                <img src="/assets/images/book-icon.svg" alt="" />
            </a>
            <!-- Side bar navigation link -->
        </li>
        <!-- /Side bar links item -->

        <!-- Side bar links item -->
        <!-- <li class="sh-side-bar-list-item ico-selected" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"> -->
          <li class="sh-side-bar-list-item  {{IsNavigationOpenReports ? 'ico-selected' : ''}}" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
            <!-- Side bar navigation link -->
            <a href="#" class="sidebar-icon-link" [routerLink]="['createreports']"  (click)= "navigationToggle('reports')">
                <img src="/assets/images/marketing-icon.svg" alt="" />
            </a>
            <!-- Side bar navigation link -->
        </li>


        <!-- /Side bar links item -->

        <!-- Side bar links item -->
        <li class="sh-side-bar-list-item  {{IsNavigationOpenUsers ? 'ico-selected' : ''}}" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
            <!-- Side bar navigation link -->
            <a href="#" class="sidebar-icon-link" [routerLink]="['users']"  (click)= "navigationToggle('users')">
                <img src="/assets/images/user-icon.svg" alt="" />
            </a>
            <!-- Side bar navigation link -->
        </li>
        <!-- /Side bar links item -->

        <!-- Side bar links item -->
        <li class="sh-side-bar-list-item  {{IsNavigationOpenDefault ? 'ico-selected' : ''}}">
            <!-- Side bar navigation link -->
            <a href="#" class="sidebar-icon-link" [routerLink]="['liststeps']"  (click)= "navigationToggle('liststeps')">
                <img src="/assets/images/gear.svg" alt=""  (click)= "navigationToggle('default')" />
            </a>
            <!-- Side bar navigation link -->
        </li>
        <!-- /Side bar links item -->


    </ul>
      </div>
    <!-- /Side bar links list -->

  </nav>
  <!-- ===== /Component - side-bar ===== -->
