
<div  *ngFor="let a of steps" style="list-style-type:none">
  <div class="form-group">
    <div *ngIf="a.step.questionType == 1" >
      <app-yesnoanswers [step] = a.step  (stepWithObservations)="collectObservations($event)" ></app-yesnoanswers>
    </div>
    <div *ngIf="a.step.questionType == 2 || a.step.questionType == 3" >
      <app-shortanswer  [step] = a.step  (stepWithObservations)="collectObservations($event)" ></app-shortanswer>
    </div>
    <div *ngIf="a.step.questionType == 4" >
      <app-singlechoiceanswer [step] = a.step  (stepWithObservations)="collectObservations($event)">
      </app-singlechoiceanswer>
    </div>
    <div *ngIf="a.step.questionType == 5" >
      <app-scaleanswer  [step] = a.step  (stepWithObservations)="collectObservations($event)" >
      </app-scaleanswer>
    </div>
    <div *ngIf="a.step.questionType == 6" >
      <app-multiplechoiceanswer [step] = a.step (stepWithObservations)="collectObservations($event)" >
      </app-multiplechoiceanswer>
    </div>
    <hr>
  </div>
</div>

<hr>





