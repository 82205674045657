import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Sections } from 'src/app/models/sections';
import { Router } from '@angular/router';
import { SectionsService } from 'src/app/services/sections.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategorySections } from 'src/app/models/category-sections';
import { Categories } from 'src/app/models/categories';

@Component({
  selector: 'app-createsection',
  templateUrl: './createsection.component.html',
  styleUrls: ['./createsection.component.css']
})
export class CreatesectionComponent implements OnInit {

  section: Sections = {} as Sections;
  category: Categories = {} as Categories;
  categorySections: CategorySections = {} as CategorySections;


  @Input() categoryId: number;
  @Output() sectioncreated = new EventEmitter();

  constructor(private router: Router,
              private sectionsService: SectionsService,
              private SpinnerService: NgxSpinnerService,
              private activeModal: NgbModal) {

               }

  ngOnInit(): void {
  }

  Save(section: Sections): void {
    this.SpinnerService.show();

    this.sectionsService.create(section).subscribe(res => {
        console.log('Section created successfully!');
        this.sectioncreated.emit({ event, sectionId: res.id });
    });
  }
}
