import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  HttpClientModule, HttpClient } from '@angular/common/http';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

import { NgxBootstrapTreeviewModule } from 'ngx-bootstrap-treeview';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';

import { CreateauditComponent } from './components/audit/createaudit/createaudit.component';
import { EditauditComponent } from './components/audit/editaudit/editaudit.component';
import { ListauditsComponent } from './components/audit/listaudits/listaudits.component';

import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { AuthGuard } from './utilities/auth.guard';
import { HttpInterceptorProviders } from './utilities/interceptors/interceptor-provider';
import {  NgbdSortableHeader } from './sortable.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CreateStepComponent } from './components/steps/createstep/createstep.component';
import { ListStepsComponent } from './components/steps/liststeps/liststeps.component';
import { EditStepComponent } from './components/steps/editstep/editstep.component';
import { AuditstepsComponent } from './components/steps/auditsteps/auditsteps.component';
import { ListusersComponent } from './components/admin/usermangement/listusers/listusers.component';
import { AdduserComponent } from './components/admin/usermangement/adduser/adduser.component';
import { UpdateuserComponent } from './components/admin/usermangement/updateuser/updateuser.component';
import { ListcompaniesComponent } from './components/companies/listcompanies/listcompanies.component';
import { ListbranchesComponent } from './components/companies/listbranches/listbranches.component';
import { AuditcategoriesComponent } from './components/categories/auditcategories/auditcategories.component';
import { DecimalPipe } from '@angular/common';
import { EditcategoriesComponent } from './components/categories/editcategories/editcategories.component';
import { CreatecategoryComponent } from './components/categories/createcategory/createcategory.component';
import { CategorystepsComponent } from './components/steps/categorysteps/categorysteps.component';
import { ListinspectionsComponent } from './components/inspection/listinspections/listinspections.component';
import { CreateinspectionComponent } from './components/inspection/createinspection/createinspection.component';
import { EditinspectionComponent } from './components/inspection/editinspection/editinspection.component';
import { TopnavigationComponent } from './components/navigations/topnavigation/topnavigation.component';
import { TopsubnavigationComponent } from './components/navigations/topsubnavigation/topsubnavigation.component';
import { InspectionstepsComponent } from './components/steps/inspectionsteps/inspectionsteps.component';
import { ListauditreportsComponent } from './components/auditreports/listauditreports/listauditreports.component';
import { CreateauditreportsComponent } from './components/auditreports/createauditreports/createauditreports.component';
//import { InspectionstepsComponent } from './components/steps/inspectionsteps/inspectionsteps.component';
import { CategoryinspectionsComponent } from './components/categories/categoryinspections/categoryinspections.component';
import { AnswerstepsComponent } from './components/observations/answersteps/answersteps.component';
import { AuditanswerstepsComponent } from './components/observations/auditanswersteps/auditanswersteps.component';
import { CategoryanswerstepsComponent } from './components/observations/categoryanswersteps/categoryanswersteps.component';
import { YesnoanswersComponent } from './components/observations/answerTypes/yesnoanswers/yesnoanswers.component';
import { ShortanswerComponent } from './components/observations/answerTypes/shortanswer/shortanswer.component';
import { PassfailanswerComponent } from './components/observations/answerTypes/passfailanswer/passfailanswer.component';
import { SinglechoiceanswerComponent } from './components/observations/answerTypes/singlechoiceanswer/singlechoiceanswer.component';
import { ScaleanswerComponent } from './components/observations/answerTypes/scaleanswer/scaleanswer.component';
import { MultiplechoiceanswerComponent } from './components/observations/answerTypes/multiplechoiceanswer/multiplechoiceanswer.component';
import { CategorysectionsComponent } from './components/sections/categorysections/categorysections.component';
import { CreatesectionComponent } from './components/sections/createsection/createsection.component';
import { EditsectionComponent } from './components/sections/editsection/editsection.component';
import { SectionstepsComponent } from './components/steps/sectionsteps/sectionsteps.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { SectionsubsectionsComponent } from './components/subsections/sectionsubsections/sectionsubsections.component';
import { CreatesubsectionComponent } from './components/subsections/createsubsection/createsubsection.component';
import { EditsubsectionComponent } from './components/subsections/editsubsection/editsubsection.component';
import { SectioninspectionsComponent } from './components/inspection/sectioninspections/sectioninspections.component';
import { ListsectionsComponent } from './components/sections/listsections/listsections.component';
import { ListcategoriesComponent } from './components/categories/listcategories/listcategories.component';
import { ListsubsectionsComponent } from './components/subsections/listsubsections/listsubsections.component';
import { SubsectionstepsComponent } from './components/steps/subsectionsteps/subsectionsteps.component';
import { SubsectioninspectionsComponent } from './components/inspection/subsectioninspections/subsectioninspections.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuditTreeviewComponent } from './components/audit/audit-treeview/audit-treeview.component';
import { LeftnavigationComponent } from './components/navigations/leftnavigation/leftnavigation.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CreateauditComponent,
    EditauditComponent,
    ListauditsComponent,

    NgbdSortableHeader,
    CreateStepComponent,
    ListStepsComponent,
    EditStepComponent,
    AuditstepsComponent,
    ListusersComponent,
    AdduserComponent,
    UpdateuserComponent,
    ListcompaniesComponent,
    ListbranchesComponent,
    AuditcategoriesComponent,
    EditcategoriesComponent,
    CreatecategoryComponent,
    CategorystepsComponent,
    ListinspectionsComponent,
    CreateinspectionComponent,
    EditinspectionComponent,
    TopnavigationComponent,
    TopsubnavigationComponent,
    CategoryinspectionsComponent,
    TopsubnavigationComponent,
    CategorystepsComponent,
    ListauditreportsComponent,
    CreateauditreportsComponent,
    AnswerstepsComponent,
    AuditanswerstepsComponent,
    YesnoanswersComponent,
    ShortanswerComponent,
    PassfailanswerComponent,
    SinglechoiceanswerComponent,
    ScaleanswerComponent,
    MultiplechoiceanswerComponent,
    InspectionstepsComponent,
    CategorysectionsComponent,
    CreatesectionComponent,
    EditsectionComponent,
    SectionstepsComponent,
    SectionsubsectionsComponent,
    CreatesubsectionComponent,
    EditsubsectionComponent,
    SectioninspectionsComponent,
    ListsectionsComponent,
    ListcategoriesComponent,
    ListsubsectionsComponent,
    SubsectionstepsComponent,
    SubsectioninspectionsComponent,
    CategoryanswerstepsComponent,
    DashboardComponent,
    AuditTreeviewComponent,
    LeftnavigationComponent



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AmplifyUIAngularModule,
    AmplifyAngularModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbModule,
    AmplifyUIAngularModule ,
    CommonModule,
    UiSwitchModule,
    NgxBootstrapTreeviewModule
  ],
  providers: [ AmplifyService,
                AuthGuard,
                HttpInterceptorProviders,
                DecimalPipe,
                NgbActiveModal
               ],
  bootstrap: [AppComponent,
              DecimalPipe ],
  exports: []
})
export class AppModule { }
