import { Categories } from './categories';
import { Sections } from './sections';

export class CategorySections {
  id: number;
  sectionId: number | null;
  categoryId: number | null;
  createdDate: string | null;
  createdBy: number | null;
  category: Categories;
  section: Sections;
}
