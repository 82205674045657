<div class="container">
  <h5>Steps</h5>
  <div class="card">
    <app-liststeps [isallsteps]="false"
                  [steps]='sortedsteps'
                  (eventStepCreated)="stepCreated($event)"
                  (eventStepUpdated)="stepUpdated($event)"
                  (eventStepDeleted)="stepDeleted($event)"></app-liststeps>
  </div>
</div>
