import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Sections } from '../models/sections';
import { CategorySections } from '../models/category-sections';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SectionsService {

  constructor(private http: HttpClient) { }

  getactivesections(): Observable<Sections[]>{
    return this.http.get<Sections[]>(environment.auditUrl + 'api/Sections/' )
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  getcategorysections(categoryId: number): Observable<Sections[]>{
    return this.http.get<Sections[]>(environment.auditUrl + 'api/Sections/GetCategorySections/' + categoryId)
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  getsection( sectionId: number): Observable<Sections>{
    return this.http.get<Sections>(environment.auditUrl + 'api/Sections/' + sectionId)
            .pipe(map((res: any) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  createcategorysections(categoryId: number, sectionId: number){
    return this.http.post(environment.auditUrl + 'api/Sections/CreateCategorySection/' + categoryId + '/' + sectionId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  create(section: Sections): Observable<Sections>{
    return this.http.post<Sections>(environment.auditUrl + 'api/Sections/', section)
    .pipe(map((res: Sections) => {
      if (res) {
        return res as Sections;
      } else {
        return null;
      }
    }));
  }

  update(categorySections: CategorySections){
    return this.http.put(environment.auditUrl + 'api/Sections/UpdateCategorySection/', categorySections)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(sectionId: number): Observable<Sections[]>{
    return this.http.delete<Sections[]>(environment.auditUrl + 'api/Sections/' +  sectionId )
    .pipe(map((res: Sections[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deletecategorysection(categoryId: number, sectionId: number): Observable<Sections[]>{
    return this.http.delete<Sections[]>(environment.auditUrl + 'api/Sections/DeleteCategorySection/' + categoryId + '/' + sectionId )
    .pipe(map((res: Sections[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }

}
