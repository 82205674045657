export interface Employee {
  employeeId: number | null;
  aspNetLoginId: string;
  employeeTypeId: number | null;
  employeeType: string;
  companyId: number | null;
  company: string;
  companyBranchId: number | null;
  companyBranch: string;
  companyLogo: string;
  companyBranchLogo: string;
  employeeJobTitleId: number | null;
  employeeJobTitle: string;
  employeeIdNumber: string;
  licenseNumber: string;
  firstName: string;
  lastName: string;
  fullName: string;
  employeeName: string;
  phoneNumber: string;
  address: string;
  lastLogin: string | null;
  employeeGroupId: number | null;
  employeeGroup: string;
  employeeDepartmentId: number | null;
  employeeDepartment: string;
  division: string;
  extension: number | null;
  fax: string;
  email: string;
  personalEmail: string;
  companyPhone: string;
  bio: string;
  contracterCompany: string;
  contracterCompanyPhone: string;
  contracterCompanyJobTitle: string;
  password: string;
  aspNetUserId: string;
  userName: string;
  role: string;
  applicationRole: string;
  dateOfHire: string | null;
  dateOfBirth: string | null;
  dateOFBirthString: string;
  dateOFHireString: string;
  dateOFEndString: string;
  endDate: string | null;
  userType: UserType | null;
  userTypeId: number;
  socialSecurityNumber: string;
  homeAddress: string;
  countryId: number | null;
  countryName: string;
  stateId: number | null;
  stateName: string;
  city: string;
  zipCode: string;
  lLPhoneNumber: string;
  emergencyContact: string;
  lLEmergencyContactPhone: string;
  status: boolean;
  statusText: string;
  photo: string;
  assetStatus: number | null;
  barcode: string;
  rFID: string;
  buildingLocationId: number | null;
  areaId: number | null;
  assetId: number | null;
  note: string;
  employeePhotoByte: string;
  modifiedDate: string | null;
  createdDate: string | null;
  isDeleted: boolean;
  isActive: boolean;
  expire: number | null;
  companyState: number | null;
  companyDeleted: boolean;
  contactUsLink: string;
  parentCompanyId: number | null;
  parentCompanyName: string;
  isUserActive: number;
  userRole: string;
  isFirstTimeLogin: boolean | null;
  totalCountHazard: number | null;
  responseMessage: string;
  timeZone: string;
}

export enum UserType {
  Asset = 1,
  User = 2,
  UserAsset = 3
}

export enum EmployeeRole {
  ReadOnly = 1,
  User = 2,
  Admin = 3,
  SuperAdmin = 4
}

export const EmployeeRoleToLabel: Record<string, string> = {
  ReadOnly : 'Read Only',
  User: 'User',
  Admin: 'Admin',
  SuperAdmin: 'Super Admin'
};
