
 <label>  {{stepWithOptions.id}} . {{stepWithOptions.question}}</label>

<!-- short answer text -->
 <div  *ngIf="stepWithOptions.questionType == 2" >
  <input type="number" class="form-control"   [(ngModel)]="shortAnswer" (ngModelChange)="textChange($event)">
</div>

<!-- short answer value -->
<div *ngIf="stepWithOptions.questionType == 3" >
  <input type="text" class="form-control"  [(ngModel)]="shortAnswer" (ngModelChange)="textChange($event)">
</div>
