import { Component, OnInit, PipeTransform, ElementRef, ViewChildren, QueryList, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { SubSections } from 'src/app/models/sub-sections';
import { SortEvent, SortDirection, NgbdSortableHeader } from 'src/app/sortable.directive';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';
import { PaginationState } from 'src/app/utilities/paginationState';
import { tap, debounceTime, switchMap, delay } from 'rxjs/operators';
import { SubsectionsService } from 'src/app/services/subsections.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';

interface SearchResult {
  subsections: SubSections[];
  total: number;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(subsections: SubSections[], column: string, direction: string): SubSections[] {
  if (direction === '') {
    return subsections;
  } else {
    return [...subsections].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(subsection: SubSections, term: string, pipe: PipeTransform) {
  return subsection.name.toLowerCase().includes(term.toLowerCase())
    || pipe.transform(subsection.id).includes(term);

}

@Component({
  selector: 'app-listsubsections',
  templateUrl: './listsubsections.component.html',
  styleUrls: ['./listsubsections.component.css']
})
export class ListsubsectionsComponent implements OnInit {

  // tslint:disable-next-line: no-input-rename
 @Input() subsections: SubSections[] = [];
 @Input() isallsubsections = false;
 @Output() eventSubSectionCreated = new EventEmitter();
 @Output() eventSubSectionUpdated = new EventEmitter();
 @Output() eventSubSectionDeleted = new EventEmitter();

 modalRef: NgbModalRef;

 @ViewChild('addsubsectionmodal') addsubsectionmodal: ElementRef;
 @ViewChild('editsubsectionmodal') editsubsectionmodal: ElementRef;
 @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

 subsections$: BehaviorSubject<SubSections[]> = new BehaviorSubject<SubSections[]>([]);
 total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
 loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true) ;


 // tslint:disable-next-line: variable-name
 private _search$ = new Subject<void>();

  state: PaginationState = {
   page: 1,
   pageSize: 10,
   searchTerm: '',
   sortColumn: '',
   sortDirection: ''
  };

 constructor(private subSectionsService: SubsectionsService,
             private router: Router,
             private spinnerService: NgxSpinnerService,
             private modalService: NgbModal,
             private pipe: DecimalPipe) {
               if (this.router.url === '/listsubsections')
               {
                 this.isallsubsections = true;
               }
  }

 ngOnInit(): void {
  this.loadsubsections();
 }

 // tslint:disable-next-line: use-lifecycle-interface
 ngOnChanges(): void{

   if (!this.isallsubsections)
   {
     this._search$.pipe(
       tap(() => this.loading$.next(true)),
       debounceTime(200),
       switchMap(() => this._search()),
       delay(200),
       tap(() => this.loading$.next(false))
     ).subscribe(result => {
       this.subsections$.next(result.subsections);
       this.total$.next(result.total);
     });

     this._search$.next();
   }
 }

 subsectioncreated(t: any){
   debugger;
   if (t && t.subsectionId)
   {
       this.eventSubSectionCreated.emit({ event, subsectionId: t.subsectionId });
   }
   this.modalRef.close();
   this.loadsubsections();
 }

 subsectionupdated(t: any){
   debugger;
   this.eventSubSectionUpdated.emit();
   this.modalRef.close();
   this.loadsubsections();
 }

 private loadsubsections() {
  if (this.isallsubsections) {
     this.spinnerService.show();
     this.subSectionsService.getsubsections().subscribe((res) => {
       this.spinnerService.hide();
       this.subsections = res;

       this._search$.pipe(
         tap(() => this.loading$.next(true)),
         debounceTime(200),
         switchMap(() => this._search()),
         delay(200),
         tap(() => this.loading$.next(false))
       ).subscribe(result => {
         this.subsections$.next(result.subsections);
         this.total$.next(result.total);
       });

       this._search$.next();
     })
       // tslint:disable-next-line: no-unused-expression
       , (err: any) => {
         this.spinnerService.hide();
         console.log(err);
       };
   }
 }

 addSubSection(): void{
   this.modalRef = this.modalService.open(this.addsubsectionmodal, { size: 'md', backdrop: 'static'});
 }

 editSubSection(e): void{
   window.localStorage.removeItem('subsectionId');
   window.localStorage.setItem('subsectionId', e.id.toString());
   this.modalRef = this.modalService.open(this.editsubsectionmodal, { size: 'xl', backdrop: 'static'});
 }

 deleteSubSection(subsection: SubSections): void{
   debugger;
   if (confirm('Are you sure to delete ' + subsection.name + '?')) {
     if (this.isallsubsections)
     {
       this.spinnerService.show();
       this.subSectionsService.delete(subsection.id).subscribe((res) => {
          console.log('Section deleted successfully!');
          this.subsections = res;

          this._search$.pipe(
           tap(() => this.loading$.next(true)),
           debounceTime(200),
           switchMap(() => this._search()),
           delay(200),
           tap(() => this.loading$.next(false))
         ).subscribe(result => {
           this.subsections$.next(result.subsections);
           this.total$.next(result.total);
         });

          this._search$.next();

          this.spinnerService.hide();
        })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.spinnerService.hide();
          console.log(err);
        };
     }

     this.eventSubSectionDeleted.emit({ event, subsectionId: subsection.id });
   }
 }

 get page() { return this.state.page; }
 get pageSize() { return this.state.pageSize; }

 // tslint:disable-next-line: adjacent-overload-signatures
 set page(page: number) { this._set({page}); }
 // tslint:disable-next-line: adjacent-overload-signatures
 set pageSize(pageSize: number) { this._set({pageSize}); }
 set searchTerm(searchTerm: string) { this._set({searchTerm}); }
 set sortColumn(sortColumn: string) { this._set({sortColumn}); }
 set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

 private _set(patch: Partial<PaginationState>) {
   Object.assign(this.state, patch);
   this._search$.next();
 }


 private _search(): Observable<SearchResult> {

   const {sortColumn, sortDirection, pageSize, page, searchTerm} = this.state;
   // 1. sort
   let subsections  = sort(this.subsections, sortColumn, sortDirection);

   // 2. filter
   if (subsections)
   {
    subsections = subsections.filter(x => matches(x, searchTerm, this.pipe));
    const total = subsections.length;
     // 3. paginate
    subsections = subsections.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

    return of({subsections, total});

   }

   return of({subsections, total: 0});

 }

 onSort({column, direction}: SortEvent) {

   // resetting other headers
   this.headers.forEach(header => {
     if (header.sortable !== column) {
       header.direction = '';
     }
   });

   this.sortColumn = column;
   this.sortDirection = direction;
 }
}
