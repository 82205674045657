import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { StepService } from 'src/app/services/step.service';
import { Steps } from 'src/app/models/steps';
import { Audits } from 'src/app/models/audits';

@Component({
  selector: 'app-auditanswersteps',
  templateUrl: './auditanswersteps.component.html',
  styleUrls: ['./auditanswersteps.component.css']
})
export class AuditanswerstepsComponent implements OnInit {

   @Input() auditid: number;
   @Input() audit: any;
   @Output() auditWithObservations : EventEmitter<any> = new EventEmitter<any>();
   auditSteps: Steps[];

   constructor(private stepservice: StepService,
               private SpinnerService: NgxSpinnerService) {
    }

   ngOnInit(): void {
     this.analyseSelectedAuditDetails();
   }


  analyseSelectedAuditDetails()
  {
    if(this.audit != null)
    {
      this.auditSteps = this.audit.auditSteps;
    }
  }

  collectObservations(event: Steps[])
  {
    if(event != null)
    {
      if(event.length > 0)
      {
        this.audit.auditSteps = event;
        this.auditWithObservations.emit(this.audit);

      }
    }
  }



}
