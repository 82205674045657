<div class="container">
  <h5>Sections</h5>
    <app-listsections
        [isallsections]="false"
        [sections]='sections'
        (eventSectionCreated)="sectioncreated($event)"
        (eventSectionUpdated)="sectionupdated($event)"
        (eventSectionDeleted)="sectiondeleted($event)"></app-listsections>

</div>
