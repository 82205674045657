import { Component, OnInit, Input } from '@angular/core';
import { Inspections } from 'src/app/models/inspections';
import { InspectionService } from 'src/app/services/inspection.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-categoryinspections',
  templateUrl: './categoryinspections.component.html',
  styleUrls: ['./categoryinspections.component.css']
})
export class CategoryinspectionsComponent implements OnInit {

  inspections: Inspections[];

 // tslint:disable-next-line: no-input-rename
  @Input() categoryId: number;

  constructor(private inspectionService: InspectionService,
              private SpinnerService: NgxSpinnerService) {
   }

  ngOnInit(): void {
    this.loadinspections();
  }

  inspectionCreated(t: any){
    this.SpinnerService.show();
    if (t && t.inspectionId)
    {
      this.inspectionService.createcategoryinspection(this.categoryId, t.inspectionId).subscribe((res) => {
          this.SpinnerService.hide();
          this.loadinspections();
        })
          // tslint:disable-next-line: no-unused-expression
          , (err: any) => {
            this.SpinnerService.hide();
            console.log(err);
          };
    }
  }

  inspectionUpdated(t: any){
    this.loadinspections();
  }

  inspectionDeleted(t: any): void{
    this.SpinnerService.show();
    this.inspectionService.deletecategoryinspection(this.categoryId, t.inspectionId).subscribe((res) => {
        console.log('Category Inspection deleted successfully!');
        this.inspections = res;
        this.loadinspections();
        this.SpinnerService.hide();
      });
  }

  private loadinspections() {

    if (!(this.categoryId) || this.categoryId === 0)
    {
      const categoryId = window.localStorage.getItem('categoryId');
      if (!categoryId) {
        return;
      }
      else
      {
        this.categoryId = Number(categoryId);
      }

    }
    this.SpinnerService.show();
    this.inspectionService.getcategoryinspections(this.categoryId).subscribe((res) => {
        this.SpinnerService.hide();
        this.inspections = res;
      })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.SpinnerService.hide();
          console.log(err);
        };
      }
}
