<!-- Navigation Bar -->

<!-- ===== Header ===== -->
<div class="container-fluid">
    
  <!-- Header section -->
  <div class="sh-header">
  
  <!-- Header row -->
  <div class="row justify-content-between">
  <div class="col-lg-3">
        
          <!-- Component - logo -->	
                  <a href="#" class="d-block">
                      <img src="/assets/images/logo.svg" class="img-fluid">
                  </a>
            <!-- /Component - logo -->
          
  </div>  
      
  <div class="col-lg-6 text-right">
    
                <!-- Component - profile details -->
                  <div class="sh-profile mt-3 d-inline-block mr-0 mr-lg-5">
                      <img src="/assets/images/user-profile-icon.svg" alt="" class="d-inline-block mr-3" />
                      <span class="d-inline-block sh-title-5 sh-text-blue">Hello {{userName}} !</span>
                  </div>
                <!-- /Component - profile details -->
      
                  <div class="sh-menu-wrapper d-inline-block ml-3">
                      <div class="dropdown">
                        <button class="btn dropdown-toggle sh-dropdown-toggle mt-3" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src="/assets/images/menu-icon.svg" alt="" class="d-block" />
                            <span class="sh-profile-menu sh-text-blue d-block">Menu</span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item" (click)="onLogoutClick()">Log out</a>
                        </div>
                      </div>
                  </div>
    
  </div> 
      
  </div>
  <!-- /Header row -->
  
  </div>
  <!-- /Header section -->.
      
  </div>
  <!-- ===== /Header ===== -->






<!-- <header>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="#"><strong>Safety Audit Pro</strong></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="#"> Hello {{userName}}!</a>
        </li>
        <li class="nav-item active">
          <button class="btn" (click)="onLogoutClick()">Logout</button>
        </li>
      </ul>
    </div>
  </nav>
</header> -->

<div *ngIf="isAuthenticated">
  <ul class="nav flex-column flex-sm-row nav-tabs">
    <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
      <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['listaudits']">Library</a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
      <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['createreports']">Reports</a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
      <a class="flex-sm-fill text-sm-center nav-link" [routerLink]="['users']">Admin</a>
    </li>
  </ul>
</div>
