import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';

import { Categories } from 'src/app/models/categories';
import { AuditCategories } from 'src/app/models/audit-categories';
import {CategoryService} from '../../../services/category.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-editcategories',
  templateUrl: './editcategories.component.html',
  styleUrls: ['./editcategories.component.css']
})
export class EditcategoriesComponent implements OnInit {

  form: FormGroup;
  category: Categories = {} as Categories;

  @Input() auditId: number;
  @Output() categoryupdated = new EventEmitter();

  constructor(public categoryService: CategoryService,
              private router: Router,
              private formBuilder: FormBuilder,
              private SpinnerService: NgxSpinnerService,
              private activeModal: NgbModal) {
                this.form = this.formBuilder.group({
                  id: ['', [ Validators.required ]],
                  name: '',
                });
                this.loadCategory();
              }

  ngOnInit(): void {
  }

  patchForm() {
    this.form.patchValue({
      id: this.category.id
    });
    this.form.patchValue({
      name: this.category.name
    });
  }

  loadCategory(): void{
    const categoryId = window.localStorage.getItem('categoryId');
    if (!categoryId) {
      alert('Invalid action.');
      return;
    }
    this.SpinnerService.show();
    this.categoryService.GetCategory(Number(categoryId))
      .subscribe( data => {
        this.category = data as Categories;
        this.patchForm() ;
        this.SpinnerService.hide();
      });
  }

  submit(){
    console.log(this.form.value);
    // tslint:disable-next-line: prefer-const
    let auditCat: AuditCategories = {} as AuditCategories;
    let category: Categories = {} as Categories;
    category = this.form.value;
    auditCat.category = category;
    auditCat.auditId = this.auditId;
    this.categoryService.update(auditCat).subscribe(res => {
      console.log('Audit Catogery updated successfully!');
      this.categoryupdated.emit();
      this.activeModal.dismissAll('Reason');
    });
  }
}
