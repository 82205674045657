import { Component, EventEmitter, Input, Output, QueryList, ViewChildren, OnInit, ElementRef, ViewChild , PipeTransform } from '@angular/core';
import {Observable , BehaviorSubject , Subject , of } from 'rxjs';
import { InspectionService } from 'src/app/services/inspection.service';
import { Inspections } from 'src/app/models/inspections';
import { Router } from '@angular/router';
import {NgbdSortableHeader, SortEvent} from 'src/app/sortable.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {DecimalPipe} from '@angular/common';
import {SortDirection } from 'src/app/sortable.directive';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';
import {PaginationState } from 'src/app/utilities/paginationState';

interface SearchResult {
  inspections: Inspections[];
  total: number;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(inspections: Inspections[], column: string, direction: string): Inspections[] {
  if (direction === '') {
    return inspections;
  } else {
    return [...inspections].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(inspections: Inspections, term: string, pipe: PipeTransform) {
  return inspections.name.toLowerCase().includes(term.toLowerCase())
    || pipe.transform(inspections.id).includes(term);

}

@Component({
  selector: 'app-listinspections',
  templateUrl: './listinspections.component.html',
  styleUrls: ['./listinspections.component.css']
})
export class ListinspectionsComponent implements OnInit {

  // tslint:disable-next-line: no-input-rename
  @Input() inspections: Inspections[] = [];
  @Input() isallinspections = false;
  @Output() eventInspectionCreated = new EventEmitter();
  @Output() eventInspectionUpdated = new EventEmitter();
  @Output() eventInspectionDeleted = new EventEmitter();

  modalRef: NgbModalRef;

  @ViewChild('addInspectionModal') addInspectionModal: ElementRef;
  @ViewChild('editInspectionModal') editInspectionModal: ElementRef;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  inspections$: BehaviorSubject<Inspections[]> = new BehaviorSubject<Inspections[]>([]);
  total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true) ;


  // tslint:disable-next-line: variable-name
  private _search$ = new Subject<void>();

   state: PaginationState = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  constructor(private inspectionService: InspectionService,
              private router: Router,
              private SpinnerService: NgxSpinnerService,
              private modalService: NgbModal,
              private pipe: DecimalPipe) {
                if (this.router.url === '/inspections')
                {
                  this.isallinspections = true;
                }
   }

  ngOnInit(): void {
    this.loadinspections();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(): void{

    if (!this.isallinspections)
    {
      this._search$.pipe(
        tap(() => this.loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this.loading$.next(false))
      ).subscribe(result => {
        this.inspections$.next(result.inspections);
        this.total$.next(result.total);
      });

      this._search$.next();
    }
  }

  inspectionCreated(t: any){
    if (t && t.inspectionId)
    {
        this.eventInspectionCreated.emit({ event, inspectionId: t.inspectionId });
    }
    this.modalRef.close();
    this.loadinspections();
  }

  inspectionUpdated(t: any){
    this.eventInspectionUpdated.emit();
    this.modalRef.close();
    this.loadinspections();
  }

  private loadinspections() {

    if (this.isallinspections) {
      this.SpinnerService.show();

      this.inspectionService.GetActiveInspections().subscribe((res) => {
        this.SpinnerService.hide();
        this.inspections = res;

        this._search$.pipe(
          tap(() => this.loading$.next(true)),
          debounceTime(200),
          switchMap(() => this._search()),
          delay(200),
          tap(() => this.loading$.next(false))
        ).subscribe(result => {
          this.inspections$.next(result.inspections);
          this.total$.next(result.total);
        });

        this._search$.next();
      })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.SpinnerService.hide();
          console.log(err);
        };
    }
  }

  addInspection(): void{
    this.modalRef = this.modalService.open(this.addInspectionModal, { size: 'md', backdrop: 'static'});
  }

  editInspection(e): void{
    window.localStorage.removeItem('inspectionId');
    window.localStorage.setItem('inspectionId', e.id.toString());
    this.modalRef = this.modalService.open(this.editInspectionModal, { size: 'xl', backdrop: 'static'});
  }

  deleteInspection(inspection: Inspections): void{
    if (confirm('Are you sure to delete ' + inspection.name + '?')) {
      if (this.isallinspections)
      {
        this.SpinnerService.show();
        this.inspectionService.delete(inspection).subscribe((res) => {
           console.log('Post created successfully!');
           this.inspections = res;

           this._search$.pipe(
            tap(() => this.loading$.next(true)),
            debounceTime(200),
            switchMap(() => this._search()),
            delay(200),
            tap(() => this.loading$.next(false))
          ).subscribe(result => {
            this.inspections$.next(result.inspections);
            this.total$.next(result.total);
          });

           this._search$.next();

           this.SpinnerService.hide();
         });
      }

      this.eventInspectionDeleted.emit({ event, inspectionId: inspection.id });
    }
  }

  get page() { return this.state.page; }
  get pageSize() { return this.state.pageSize; }

  // tslint:disable-next-line: adjacent-overload-signatures
  set page(page: number) { this._set({page}); }
  // tslint:disable-next-line: adjacent-overload-signatures
  set pageSize(pageSize: number) { this._set({pageSize}); }
  set searchTerm(searchTerm: string) { this._set({searchTerm}); }
  set sortColumn(sortColumn: string) { this._set({sortColumn}); }
  set sortDirection(sortDirection: SortDirection) { this._set({sortDirection}); }

  private _set(patch: Partial<PaginationState>) {
    Object.assign(this.state, patch);
    this._search$.next();
  }


  private _search(): Observable<SearchResult> {

    const {sortColumn, sortDirection, pageSize, page, searchTerm} = this.state;
    // 1. sort
    let inspections  = sort(this.inspections, sortColumn, sortDirection);

    // 2. filter
    if (inspections != null)
    {
      inspections = inspections.filter(x => matches(x, searchTerm, this.pipe));
      const total = inspections.length;
      // 3. paginate
      inspections = inspections.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
      return of({inspections, total});
    }

    return of({inspections, total: 0});
  }

  onSort({column, direction}: SortEvent) {

    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;
  }
}
