import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Employee } from '../models/employee';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
              private SpinnerService: NgxSpinnerService) { }

  getActiveUsersOfCompany(companyid: number): Observable<Employee[]>{
    return this.http.get<Employee[]>(environment.safetyhiveUrl + 'api/Employee/' + companyid);
  }

  getActiveUsers(): Observable<Employee[]>{
    return this.http.get<Employee[]>(environment.safetyhiveUrl + 'api/Employee/GetActiveUsers');
  }

  getUserDetails(employeeId: number): Observable<Employee>{
    return this.http.get<Employee>(environment.safetyhiveUrl + 'api/Employee/' + employeeId);
  }

  IsEmployeeAuditUser(employeeId: number): Observable<boolean>{
    return this.http.get<boolean>(environment.safetyhiveUrl + 'api/Employee/IsEmployeeAuditUser/' + employeeId);
  }

  create(employee: Employee): Observable<string>{
    try {
        const ul = environment.safetyhiveUrl + 'api/Employee/CreateUser';
        employee.isDeleted = false;
        employee.status = true;
        return this.http.post<any>(ul, employee).pipe(
          catchError(this.errorHandler)
        );
    } catch (error) {
      debugger;
      this.errorHandler(error);
    }
  }

  errorHandler(error) {
    this.SpinnerService.hide();
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }


}
