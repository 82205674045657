<div class="container">

    <div class="row align-items-center justify-content-center" style="height:100vh;">
      <div>

        <div class="row">
          <button type="button" id="sendlogin" class="btn btn-primary" (click)="onLoginClick()">
            Login with AWS
          </button>
        </div>

      </div>
    </div>

  </div>
