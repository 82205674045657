import { Component, OnInit, Input } from '@angular/core';
import { Inspections } from 'src/app/models/inspections';
import { InspectionService } from 'src/app/services/inspection.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sectioninspections',
  templateUrl: './sectioninspections.component.html',
  styleUrls: ['./sectioninspections.component.css']
})
export class SectioninspectionsComponent implements OnInit {

  inspections: Inspections[];

  // tslint:disable-next-line: no-input-rename
   @Input() sectionId: number;

   constructor(private inspectionService: InspectionService,
               private SpinnerService: NgxSpinnerService) {
    }

   ngOnInit(): void {
     this.loadinspections();
   }

   inspectionCreated(t: any){
     this.SpinnerService.show();
     if (t && t.inspectionId)
     {
       this.inspectionService.createsectioninspection(this.sectionId, t.inspectionId).subscribe((res) => {
           this.SpinnerService.hide();
           this.loadinspections();
         })
           // tslint:disable-next-line: no-unused-expression
           , (err: any) => {
             this.SpinnerService.hide();
             console.log(err);
           };
     }
   }

   inspectionUpdated(t: any){
     this.loadinspections();
   }

   inspectionDeleted(t: any): void{
     this.SpinnerService.show();
     this.inspectionService.deletesectioninspection(this.sectionId, t.inspectionId).subscribe((res) => {
         console.log('Section Inspection deleted successfully!');
         this.inspections = res;
         this.loadinspections();
         this.SpinnerService.hide();
       });
   }

   private loadinspections() {
    if (!(this.sectionId) || this.sectionId === 0)
     {
       const sectionId = window.localStorage.getItem('sectionId');
       if (!sectionId) {
         return;
       }
       else
       {
         this.sectionId = Number(sectionId);
       }

     }
    this.SpinnerService.show();
    this.inspectionService.getsectioninspections(this.sectionId).subscribe((res) => {
         this.SpinnerService.hide();
         this.inspections = res;
       })
         // tslint:disable-next-line: no-unused-expression
         , (err: any) => {
           this.SpinnerService.hide();
           console.log(err);
         };
       }
}
