<div class="container">
<form #updateForm="ngForm">
  <h3>Audit</h3>

  <div class="form-group">
    <hr>
    <input type="hidden" name="id" [(ngModel)]='audit.id'>
    <label for="Name">Audit Name</label>
    <input type="text" class="form-control" name="Name" [(ngModel)]='audit.name' size="100">
    <label for="CreatedDate">Created On</label>
    <input type="text" class="form-control" name="CreatedDate" [ngModel]="audit.createdDate |date:'dd-MM-yyyy'" readonly>
    <br>
    <input type="button" class="btn btn-primary" value="Save" (click)="Save(updateForm.value)"  ngbTooltip="Save Audit">
  </div>
  <hr>
  <app-auditsteps [auditid]='auditId'></app-auditsteps>
  <hr>
  <app-auditcategories [auditid]='auditId' (categoryCreated)="categoryCreated($event)"></app-auditcategories>
  <br/>
</form>
</div>
