import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormArray, FormBuilder, AbstractControl, FormControl} from '@angular/forms';

import { StepWithOptions } from '../../../models/step-with-options';
import {CommonService} from '../../../services/common.service';
import {StepService} from '../../../services/step.service';
import { Steps } from 'src/app/models/steps';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-createstep',
  templateUrl: './createstep.component.html',
  styleUrls: ['./createstep.component.css']
})
export class CreateStepComponent implements OnInit {
  stepForm: FormGroup;
  selectoptions: FormArray;
  questiontypes: any[] = {} as any[];

  @Output() stepCreated = new EventEmitter();

  constructor(private stepservice: StepService,
              public commonservice: CommonService,
              private SpinnerService: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private activeModal: NgbModal) {
                this.fetchformcontrolvalues();
               }

  ngOnInit(): void {
    this.stepForm = this.formBuilder.group({
      question: ['', [Validators.required, Validators.minLength(10)]],
      questiontype: ['', [Validators.required]],
      selectoptions: this.formBuilder.array([ this.createOption() ])
    });
  }

  createOption(): FormGroup {
    return this.formBuilder.group({
      text: '',
    });
  }

  getOptionsFormArrayControls(): AbstractControl[] {
    const options = (this.stepForm.get('selectoptions') as FormArray);
    return (options && options.controls) ? options.controls : [new FormControl()];
  }

  addselectoption(): void{
    this.selectoptions = this.stepForm.get('selectoptions') as FormArray;
    this.selectoptions.push(this.createOption());
  }

  removeselectoption(i: number): void{
    (this.stepForm.get('selectoptions') as FormArray).removeAt(i);
  }

  fetchformcontrolvalues(){
    this.commonservice.getQuestionTypes().subscribe(res => {
      console.log('Step created successfully!');
      this.questiontypes = res;
    });
  }

  submit(){
    this.SpinnerService.show();
    let stepwithoptions: StepWithOptions;
    const step: Steps = new Steps();
    step.question = this.stepForm.value.question;
    step.questionType = this.stepForm.value.questiontype;

    stepwithoptions = { auditId: null,
                        step,
                        stepSelectOption: (this.stepForm.value.selectoptions)};
    this.stepservice.create(stepwithoptions).subscribe(res => {
      this.SpinnerService.hide();
      const stepCreated: Steps = res as Steps;
      this.stepCreated.emit({ event, stepId: stepCreated.id });
      // this.activeModal.dismissAll('Reason');
      console.log('Step created successfully!');
    });
  }

}
