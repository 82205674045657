import { CategorySteps } from './category-steps';
import { AuditSteps } from './audit-steps';
import { InspectionSteps } from './inspection-steps';
import { Observations } from './observations';
import { SectionSteps } from './section-steps';
import { SubSectionSteps } from './sub-section-steps';
import { StepSelectOptions } from './step-select-options';

export class Steps {
  id: number;
  isdeleted: boolean | null;
  status: number | null;
  createdDate: string | null;
  modifiedDate: string | null;
  CreatedBy: number | null;
  ModifiedBy: number | null;
  questionType: number | null;
  question: string;
  optionsGroupId: number | null;
  auditSteps: AuditSteps[];
  categorySteps: CategorySteps[];
  inspectionSteps: InspectionSteps[];
  observations: Observations[];
  sectionSteps: SectionSteps[];
  subSectionSteps: SubSectionSteps[];
  stepSelectOptions: StepSelectOptions[];
}
