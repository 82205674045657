<div class="container">
  <form #newUserForm="ngForm">

    <div class="form-group">
      <label for="FirstName">First Name</label>
      <input type="text" class="form-control" name="FirstName" [(ngModel)]='user.firstName' size="100">
    </div>
    <div class="form-group">
      <label for="LastName">Last Name</label>
      <input type="text" class="form-control" name="LastName" [(ngModel)]='user.lastName' size="100">
    </div>
    <div class="form-group">
      <label for="PhoneNumber">Phone Number</label>
      <input type="text" class="form-control" name="PhoneNumber" [(ngModel)]='user.phoneNumber' size="100">
    </div>
    <div class="form-group">
      <label for="Email">Email</label>
      <input type="text" class="form-control" name="Email" [(ngModel)]='user.email' [email]="true" size="100">
    </div>
    <div class="form-group">
      <label for="Password">Password</label>
      <input type="password" class="form-control" name="Password" [(ngModel)]='user.password' size="100">
    </div>

    <div class="form-group">
      <label for="ConfirmPassword">Confirm Password</label>
      <input type="password" class="form-control" name="ConfirmPassword"  size="100">
    </div>

    <div class="form-group">
      <label for="role">User Role</label>
      <div>
        <select id="role" name="role" [(ngModel)]='user.role' >
          <option *ngFor="let r of userRoles | keyvalue" [value]="r['key']">{{r['value']}}</option>
        </select>
      </div>
    </div>


    <input type="button" value="Add" (click)="save(newUserForm.value)">
  </form>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Loading. </p>
  </ngx-spinner>
