import { Component, OnInit , Input , Output , EventEmitter } from '@angular/core';
import { StepService } from 'src/app/services/step.service';
import { Steps } from 'src/app/models/steps';

@Component({
  selector: 'app-multiplechoiceanswer',
  templateUrl: './multiplechoiceanswer.component.html',
  styleUrls: ['./multiplechoiceanswer.component.css']
})
export class MultiplechoiceanswerComponent implements OnInit {

  @Input() step: Steps;
  @Output() stepWithObservations : EventEmitter<Steps> = new EventEmitter<Steps>();
  stepWithOptions: Steps;
  selectedMultiAnswers : any[] = [] ; 


  constructor(private stepService : StepService ) { }

  ngOnInit(): void {
    this.analyseInput();
  }

  analyseInput()
  {
  
    if(this.step != null )
    {
      this.stepWithOptions = this.step ;

      if(this.stepWithOptions.observations [0] != null  )
      {
        if(this.stepWithOptions.observations[0].StepObservationMultiSelections != null )
        {
          if(this.stepWithOptions.observations[0].StepObservationMultiSelections.length > 0 )
          {
            // this.selectedAnswers = this.stepWithOptions.observations[0].StepObservationMultiSelections ; 
            this.stepWithOptions.observations[0].StepObservationMultiSelections.forEach((item: any) => {
              this.selectedMultiAnswers.push(item.StepSelctionOptionId); 
            });
            
          }
          
        
        }
        
      }
    }
  }

  tempArr: any = { "selectedOptions": [] };


  onCheckboxChange(e) {

    if (e.target.checked) {
      this.tempArr.selectedOptions.push(e.target.value);
    } else {
      let i: number = 0;
      this.tempArr.selectedOptions.forEach((item: any) => {
        if (item == e.target.value) {
          this.tempArr.selectedOptions.removeAt(i);
          return;
        }
        i++;
      });
    }

    let selctedOptions : any[] = [] ;
    this.tempArr.selectedOptions.forEach(function (value) {
      let answer:any = ({StepSelctionOptionId : value , Selected : true });
      selctedOptions.push (answer)  ;
    });


    let observation :any =  ({StepId: this.stepWithOptions.id , StepObservationMultiSelections : selctedOptions });

    this.stepWithOptions.observations =[] ;
    this.stepWithOptions.observations.push(observation);


    this.stepWithObservations.emit(this.stepWithOptions);

  }

  checked(item : number){
  
    let isInclude : boolean  = this.selectedMultiAnswers.includes(''+item+'') ; 
    return isInclude; 

  }

 


}
