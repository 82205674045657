import { Component, OnInit } from '@angular/core';
import { Audits } from 'src/app/models/audits';
import { AuditsService } from 'src/app/services/audits.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-editaudit',
  templateUrl: './editaudit.component.html',
  styleUrls: ['./editaudit.component.css']
})
export class EditauditComponent implements OnInit {

  audit: Audits  = {} as Audits;
  auditId: number;
  editForm: any;

  constructor(private router: Router,
              private auditservice: AuditsService,
              private SpinnerService: NgxSpinnerService) {
               }

  ngOnInit(): void {
    this.loadaudit();
  }

  loadaudit(): void{
    const id = window.localStorage.getItem('auditId');
    if (!id) {
      console.log('No Audit Id found');
      return;
    }
    this.SpinnerService.show();
    this.auditId = Number(id);
    this.auditservice.getAuditDetails(this.auditId)
      .subscribe( data => {
        this.SpinnerService.hide();
        this.audit = data;
      });
  }

  categoryCreated(t: any){
    this.loadaudit();
  }

  get f(){
    return this.editForm.controls;
  }

  Save(auidtUpdate: Audits): void {
    this.SpinnerService.show();
    this.auditservice.update(auidtUpdate).subscribe(res => {
        console.log('Audits created successfully!');
        this.router.navigate(['listaudits']);
    });
  }

}
