

      
  <!-- Dashboard row -->   
  <div class="row">
  <div class="col-lg-7">
  
                  <!-- Component - Dashboard -->
                  <h1 class="sh-title-1 text-uppercase">Dashboard</h1>
                  <p class="mb-0 sh-title-5">Safety Hive gives you the opportunity to enhance your safety program and enables employees to take ownership of your company safety culture.</p>
                  <!-- Component - Dashboard -->
      
  </div>
  <div class="col-lg-5 text-right">
                  
                  <!-- Component - Add report and New audit -->
                  <div class="sh-button-wrapper mt-5">
                      <button type="button" class="btn sh-lg-blue-button mr-3 mb-3">
                          <img src="/assets/images/add-icon.svg" alt="" class="mr-2" />
                          New Report
                      </button>
                      <button type="button" class="btn sh-lg-green-button mb-3">
                          <img src="/assets/images/add-icon.svg" alt="" class="mr-2" />
                          New Audit
                      </button>
                  </div>
                  <!-- Component - Add report and New audit -->
      
  </div>
  </div>
  <!-- /Dashboard row -->
      
      
  <!-- Dashboard widgets row -->
  <div class="row mt-5">
  <div class="col-lg-4 mt-3 mt-lg-3">
  
                  <!-- Widget -->
                  <div class="sh-col-widget-box">
                      <div class="sh-widget-box-header">
                          <h2 class="sh-title-2 text-uppercase">Recently Completed Audits</h2>
                      </div>
                      
                      <!-- Audit list -->
                      <ul class="sh-audit-list">
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-lg-75 sh-title-5 float-left">Electrical Safety Audit</span>
                              <div class="w-lg-25 float-right">
                                  <button type="button" class="btn sh-sm-blue-button">View</button>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-lg-75 sh-title-5 float-left">Forklift One Audit</span>
                              <div class="w-lg-25 float-right">
                                  <button type="button" class="btn sh-sm-blue-button">View</button>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-lg-75 sh-title-5 float-left">Forklift Two Audit</span>
                              <div class="w-lg-25 float-right">
                                  <button type="button" class="btn sh-sm-blue-button">View</button>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                      </ul>
                      <!-- /Audit list -->
                      
                  </div>
                  <!-- /Widget -->
      
  </div>    
  <div class="col-lg-4 mt-3 mt-lg-3">
  
                  <!-- Widget -->
                  <div class="sh-col-widget-box">
                      <div class="sh-widget-box-header">
                          <h2 class="sh-title-2 text-uppercase">audit in progress</h2>
                      </div>
                      
                      <div class="d-block float-right mt-3">
                          <span class="sh-title-7 sh-text-gray">Completed / Pending</span>
                      </div>
                      
                      <!-- Audit list -->
                      <ul class="sh-audit-list">
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-75 sh-title-5 float-left">Electrical Safety Audit</span>
                              <div class="w-25 float-right">
                                  <div class="progress sh-progress">
                                    <div class="progress-bar sh-bg-light-green" role="progressbar" style="width: 70%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">70%</div>
                                    <div class="progress-bar sh-bg-blue" role="progressbar" style="width: 30%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">30%</div>
                                  </div>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-75 sh-title-5 float-left">Forklift One Audit</span>
                              <div class="w-25 float-right">
                                  <div class="progress sh-progress">
                                    <div class="progress-bar sh-bg-light-green" role="progressbar" style="width: 40%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">40%</div>
                                    <div class="progress-bar sh-bg-blue" role="progressbar" style="width: 60%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">60%</div>
                                  </div>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-75 sh-title-5 float-left">Forklift Two Audit</span>
                              <div class="w-25 float-right">
                                  <div class="progress sh-progress">
                                    <div class="progress-bar sh-bg-light-green" role="progressbar" style="width: 60%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">60%</div>
                                    <div class="progress-bar sh-bg-blue" role="progressbar" style="width: 40%" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">40%</div>
                                  </div>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                      </ul>
                      <!-- /Audit list -->
                      
                  </div>
                  <!-- /Widget -->
      
  </div>    
  <div class="col-lg-4 mt-3 mt-lg-3">
      
                  <!-- Widget -->
                  <div class="sh-col-widget-box">
                      <div class="sh-widget-box-header">
                          <h2 class="sh-title-2 text-uppercase">audit in progress</h2>
                      </div>
                      
                      <!-- Audit list -->
                      <ul class="sh-audit-list">
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-25 sh-title-5 float-left text-uppercase">jul</span>
                              <div class="w-75 float-right">
                                  <div class="progress sh-chart-progress" style="height: 40px;">
                                    <div class="progress-bar sh-bg-green" role="progressbar" style="width: 30%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">30</div>
                                  </div>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-25 sh-title-5 float-left text-uppercase">aug</span>
                              <div class="w-75 float-right">
                                  <div class="progress sh-chart-progress" style="height: 40px;">
                                    <div class="progress-bar sh-bg-green" role="progressbar" style="width: 50%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">50</div>
                                  </div>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-25 sh-title-5 float-left text-uppercase">sep</span>
                              <div class="w-75 float-right">
                                  <div class="progress sh-chart-progress" style="height: 40px;">
                                    <div class="progress-bar sh-bg-green" role="progressbar" style="width: 20%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">20</div>
                                  </div>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-25 sh-title-5 float-left text-uppercase">oct</span>
                              <div class="w-75 float-right">
                                  <div class="progress sh-chart-progress" style="height: 40px;">
                                    <div class="progress-bar sh-bg-green" role="progressbar" style="width: 70%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">70</div>
                                  </div>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-25 sh-title-5 float-left text-uppercase">nov</span>
                              <div class="w-75 float-right">
                                  <div class="progress sh-chart-progress" style="height: 40px;">
                                    <div class="progress-bar sh-bg-green" role="progressbar" style="width: 40%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">40</div>
                                  </div>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                          <!-- Audit list item -->
                          <li class="clearfix">
                              <span class="w-25 sh-title-5 float-left text-uppercase">dec</span>
                              <div class="w-75 float-right">
                                  <div class="progress sh-chart-progress" style="height: 40px;">
                                    <div class="progress-bar sh-bg-green" role="progressbar" style="width: 60%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">60</div>
                                  </div>
                              </div>                            
                          </li>
                          <!-- Audit list item -->
                          
                      </ul>
                      <!-- /Audit list -->
                      
                  </div>
                  <!-- /Widget -->
      
  </div>   
  </div>
  <!-- /Dashboard widgets row -->

