import { Injectable , PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuditReports } from '../models/audit-reports';
import { Audits } from '../models/audits';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuditreportsService {

  constructor(private http: HttpClient ) { }

  getActiveAuditReports(): Observable<AuditReports[]>{
    return this.http.get<AuditReports[]>(environment.auditUrl + 'api/AuditReport/')
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  create(auditReport: AuditReports ){
   debugger; 
    return this.http.post<number>(environment.auditUrl + 'api/auditreport/SaveAuditReport/', auditReport)
    .pipe(
      catchError(this.errorHandler)
    );
  }


  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
  

}
