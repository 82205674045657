import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { SubSections } from '../models/sub-sections';
import { SectionSubSections } from '../models/section-sub-sections';

@Injectable({
  providedIn: 'root'
})
export class SubsectionsService {

  constructor(private http: HttpClient) { }

  getsubsections(): Observable<SubSections[]>{
    return this.http.get<SubSections[]>(environment.auditUrl + 'api/SubSections/')
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  getsectionsubsections(sectionId: number): Observable<SubSections[]>{
    return this.http.get<SubSections[]>(environment.auditUrl + 'api/SubSections/GetSectionSubSections/' + sectionId)
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  getsubsection(subsectionId: number): Observable<SubSections>{
    return this.http.get<SubSections>(environment.auditUrl + 'api/SubSections/' + subsectionId)
            .pipe(map((res: any) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  create(subSections: SubSections){
    return this.http.post<SubSections>(environment.auditUrl + 'api/SubSections/', subSections)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  createsectionssubsection(sectionId: number, subSectionId: number){
    // tslint:disable-next-line: max-line-length
    return this.http.post<SubSections>(environment.auditUrl + 'api/SubSections/CreateSectionSubSection/' + sectionId + '/' + subSectionId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(subSections: SubSections){
    return this.http.put(environment.auditUrl + 'api/SubSections/', subSections)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(subSectionId: number): Observable<SubSections[]>{
    return this.http.delete<SubSections[]>(environment.auditUrl + 'api/SubSections/' + subSectionId)
    .pipe(map((res: SubSections[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deletesectionsubsection(sectionId: number, subSectionId: number): Observable<SubSections[]>{
    // tslint:disable-next-line: max-line-length
    return this.http.delete<SubSections[]>(environment.auditUrl + 'api/SubSections/DeleteSectionSubsection/' + subSectionId + '/' + sectionId )
    .pipe(map((res: SubSections[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  errorHandler( error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }

}
