import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leftnavigation',
  templateUrl: './leftnavigation.component.html',
  styleUrls: ['./leftnavigation.component.css']
})
export class LeftnavigationComponent implements OnInit {
  IsNavigationOpenDashboard : boolean = true;
  IsNavigationOpenAudits : boolean = false;
  IsNavigationOpenReports : boolean = false;
  IsNavigationOpenUsers : boolean = false;
  IsNavigationOpenDefault : boolean = false;
  IsNavigationOpen : boolean = false;



  constructor() { }

  ngOnInit(): void {
  }

  navigationToggle(name:string): void {

    this.IsNavigationOpen = !this.IsNavigationOpen;

    if(name == 'dashboard')
    {
      this.IsNavigationOpenAudits  = false;
      this.IsNavigationOpenReports  = false;
      this.IsNavigationOpenUsers  = false;
      this.IsNavigationOpenDefault  = false;
      this.IsNavigationOpen  = false;
      this.IsNavigationOpenDashboard = true;

    }
    if(name == 'audits')
    {
      this.IsNavigationOpenAudits  = true;
      this.IsNavigationOpenReports  = false;
      this.IsNavigationOpenUsers  = false;
      this.IsNavigationOpenDefault  = false;
      this.IsNavigationOpen  = false;
      this.IsNavigationOpenDashboard = false;

    }
    if(name == 'reports')
    {
      this.IsNavigationOpenAudits  = false;
      this.IsNavigationOpenReports  = true;
      this.IsNavigationOpenUsers  = false;
      this.IsNavigationOpenDefault  = false;
      this.IsNavigationOpen  = false;
      this.IsNavigationOpenDashboard = false;
    }
    if(name == 'users')
    {
      this.IsNavigationOpenAudits  = false;
      this.IsNavigationOpenReports  = false;
      this.IsNavigationOpenUsers  = true;
      this.IsNavigationOpenDefault  = false;
      this.IsNavigationOpen  = false;
      this.IsNavigationOpenDashboard = false;
    }
    if(name == 'default')
    {
      this.IsNavigationOpenAudits  = false;
      this.IsNavigationOpenReports  = false;
      this.IsNavigationOpenUsers  = false;
      this.IsNavigationOpenDefault  = false;
      this.IsNavigationOpen  = true;
      this.IsNavigationOpenDashboard = false;
    }
  }

}
