<div class="container">

  <form>

    <div class="form-group form-inline">
      Search: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="searchTerm"/>
      <span class="ml-3" *ngIf="loading$ | async">Loading...</span>
    </div>

    <table class="table table-striped table-bordered table-sm">
      <thead>
        <tr>
          <th scope="col" sortable="id" >ID</th>
          <th scope="col" sortable="name" >Name</th>
          <th scope="col" >Address</th>
          <th scope="col" >City</th>
          <th scope="col" >State</th>
          <th scope="col" >Country</th>
          <th scope="col" >Zip Code</th>
          <th scope="col" >Phone</th>
          <th scope="col" >Status</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr class="success" *ngFor="let a of companies"> -->
        <tr class="success" *ngFor="let a of companies$ | async">
          <td scope="row">{{a.companyId}}</td>
          <td>{{a.companyName | slice:0:20}}</td>
          <td>{{a.address | slice:0:20 }}</td>
          <td>{{a.city }}</td>
          <td>{{a.state }}</td>
          <td>{{a.country }}</td>
          <td>{{a.zipCode }}</td>
          <td>{{a.phone }}</td>
          <td>{{a.status }}</td>
          <td>
            <a class="" (click)="selectBranch(a)" >
              <span class="material-icons">
                input
                </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>


    <div class="d-flex justify-content-between p-2">
      <ngb-pagination
        [collectionSize]="(total$ | async)!" [(page)]="page" [pageSize]="pageSize" [maxSize]="10">
      </ngb-pagination>

      <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="pageSize">
        <option [ngValue]="5">5 items per page</option>
        <option [ngValue]="10">10 items per page</option>
        <option [ngValue]="20">20 items per page</option>
      </select>

    </div>


  </form>


</div>

<ng-template #branchesModal let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h4  class="modal-title">Branches</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
   <app-listcompanies></app-listcompanies>
  </div>
  <div class="modal-footer">
      <!-- <button type="button" id="cancel-edit-btn" class="btn btn-primary" (click)="c('Close click')">Cancel</button> -->
  </div>
</ng-template>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Loading. </p>
</ngx-spinner>
