import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Steps } from 'src/app/models/steps';
import { FormGroup, Validators, FormArray, FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StepService } from 'src/app/services/step.service';
import { CommonService } from 'src/app/services/common.service';
import { StepWithOptions } from 'src/app/models/step-with-options';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-editstep',
  templateUrl: './editstep.component.html',
  styleUrls: ['./editstep.component.css']
})
export class EditStepComponent implements OnInit {

  stepForm: FormGroup;
  selectoptions: FormArray;
  questiontypes: any[] = {} as any[];
  stepWithOptions: StepWithOptions = {} as StepWithOptions;

  @Output() stepUpdated = new EventEmitter();

  constructor(private stepService: StepService,
              public commonservice: CommonService,
              private SpinnerService: NgxSpinnerService,
              private router: Router,
              public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder) {
                this.fetchformcontrolvalues();
              }

  ngOnInit(): void {
    this.stepForm = this.formBuilder.group({
      id: '',
      question: '',
      questiontype: '',
      optionsGroupId: '',
      selectoptions: this.formBuilder.array([  ])
    });
    this.loadstep();
  }

  createOption(): FormGroup {
    return this.formBuilder.group({
      text: ['', [Validators.required]],
      id: 0,
      delete: false
    });
  }

  fetchformcontrolvalues(){
    this.commonservice.getQuestionTypes().subscribe(res => {
      console.log('Step created successfully!');
      this.questiontypes = res;
    });
  }

  patchForm() {
    this.stepForm.patchValue({
      id: this.stepWithOptions.step.id
    });
    this.stepForm.patchValue({
      question: this.stepWithOptions.step.question
    });
    this.stepForm.patchValue({
      questiontype: this.stepWithOptions.step.questionType
    });

    this.stepForm.patchValue({
      optionsGroupId: this.stepWithOptions.step.optionsGroupId
    });


    this.setStepSelectOptions();
  }

  setStepSelectOptions(){
    const control = this.stepForm.controls.selectoptions as FormArray;
    if (this.stepWithOptions && this.stepWithOptions.stepSelectOption)
    {
      this.stepWithOptions.stepSelectOption.forEach(x => {
        control.push(this.formBuilder.group(x));
      });
    }
  }

  loadstep(): void{
    const stepId = window.localStorage.getItem('stepId');
    if (!stepId) {
      alert('Invalid action.');
      this.router.navigate(['steps/liststeps']);
      return;
    }
    this.SpinnerService.show();
    this.stepService.GetStepDetail(Number(stepId))
      .subscribe( data => {
        this.stepWithOptions = data as StepWithOptions;
        this.patchForm() ;
        this.SpinnerService.hide();
      });
  }

  getControls() {
    return (this.stepForm.get('selectoptions') as FormArray).controls;
  }

  addselectoption(): void{
    this.selectoptions = this.stepForm.get('selectoptions') as FormArray;
    this.selectoptions.push(this.createOption());
  }

  removeselectoption(button, i): void{
    const controlToRemove = (this.stepForm.get('selectoptions') as FormArray).controls[i];

    if (controlToRemove.value.delete){
      controlToRemove.value.delete = false;
      button.textContent = '-';
    }
    else
    {
      controlToRemove.value.delete = true;
      button.textContent = '+';
    }
   }


  Save(): void {
    this.SpinnerService.show();

    let stepwithoptions: StepWithOptions;
    const step: Steps = new Steps();
    step.id = this.stepForm.value.id;
    step.question = this.stepForm.value.question;
    step.questionType = this.stepForm.value.questiontype;
    step.optionsGroupId = this.stepForm.value.optionsGroupId;

    stepwithoptions = { auditId: null,
                        step,
                        stepSelectOption: this.stepForm.value.selectoptions.map((p) =>  (
                          {text: p.text,
                            id: p.id,
                            optionsGroupId: p.optionsGroupId,
                            delete: p.delete}))};

    this.stepService.update(stepwithoptions).subscribe(res => {
         console.log('Step updated successfully!');
         this.stepUpdated.emit();
    });
  }

}
