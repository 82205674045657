import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-topnavigation',
  templateUrl: './topnavigation.component.html',
  styleUrls: ['./topnavigation.component.css']
})
export class TopnavigationComponent implements OnInit {

  email: string;
  userId: string;
  userName: string;
  user: User;

  public isAuthenticated = false;


  constructor(private router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.email = '';

    Auth.currentAuthenticatedUser({
      bypassCache: false
    }).then(async user => {
      this.isAuthenticated = true;
      this.userId = user.attributes.sub;
      this.userName = user.username;
      this.email = user.attributes.email;
    }).catch(err => console.log(err));
  }

  onLogoutClick(){

    console.log('Logout Clicked');
    Auth.signOut({ global: true })
      .then(() => {
        // tslint:disable-next-line: no-unused-expression
        (        data: any) => console.log(data);
        this.router.navigate(['/login'], { replaceUrl: true });
      })
      .catch(err => {
        console.log(err);
        Auth.signOut();
        this.router.navigate(['/login'], { replaceUrl: true });
      });

  }

}
