import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Inspections } from '../models/inspections';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  constructor(private http: HttpClient) { }

  GetActiveInspections(): Observable<Inspections[]>{
    return this.http.get<Inspections[]>(environment.auditUrl + 'api/inspections/')
            .pipe(map((res: any[]) => {

              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  GetInspectionDetail(inspectionId: number): Observable<Inspections>{
    return this.http.get<Inspections>(environment.auditUrl + 'api/inspections/' + inspectionId)
            .pipe(map((res: Inspections) => {
              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  getcategoryinspections(categoryId: number): Observable<Inspections[]>{
    return this.http.get<Inspections[]>(environment.auditUrl + 'api/inspections/GetCategoryInspections/' + categoryId)
            .pipe(map((res: Inspections[]) => {
              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  getsectioninspections(sectionId: number): Observable<Inspections[]>{
    return this.http.get<Inspections[]>(environment.auditUrl + 'api/inspections/GetSectionInspections/' + sectionId)
            .pipe(map((res: Inspections[]) => {
              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  getsubsectioninspections(subsectionId: number): Observable<Inspections[]>{
    return this.http.get<Inspections[]>(environment.auditUrl + 'api/inspections/GetSubSectionInspections/' + subsectionId)
            .pipe(map((res: Inspections[]) => {
              if (res) {
                return res;
              } else {
                return null;
              }
            }));
  }

  createcategoryinspection(categoryId: number, inspectionId: number){
    return this.http.post(environment.auditUrl + 'api/inspections/CreateCategoryInspection/' + categoryId + '/' + inspectionId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  createsectioninspection(sectionId: number, inspectionId: number){
    return this.http.post(environment.auditUrl + 'api/inspections/CreateSectionInspection/' + sectionId + '/' + inspectionId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  createsubsectioninspection(subsectionId: number, inspectionId: number){
    return this.http.post(environment.auditUrl + 'api/inspections/CreateSubSectionInspection/' + subsectionId + '/' + inspectionId, null)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  create(inspections: Inspections): Observable<Inspections>{
    return this.http.post<Inspections>(environment.auditUrl + 'api/Inspections/', inspections)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  update(inspections: Inspections): Observable<Inspections>{
    return this.http.put<Inspections>(environment.auditUrl + 'api/Inspections/', inspections)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  delete(inspections: Inspections): Observable<Inspections[]>{
    return this.http.delete<Inspections[]>(environment.auditUrl + 'api/Inspections/' + inspections.id)
    .pipe(map((res: Inspections[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deletecategoryinspection(categoryId: number, inspectionId: number): Observable<Inspections[]>{
    return this.http.delete<Inspections[]>(environment.auditUrl +
                                            'api/Inspections/DeleteCategoryInspection/' +
                                            categoryId + '/' +
                                            inspectionId)
    .pipe(map((res: Inspections[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deletesectioninspection(sectionId: number, inspectionId: number): Observable<Inspections[]>{
    return this.http.delete<Inspections[]>(environment.auditUrl +
                                            'api/Inspections/DeleteSectionInspection/' +
                                            sectionId + '/' +
                                            inspectionId)
    .pipe(map((res: Inspections[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  deletesubsectioninspection(subsectionId: number, inspectionId: number): Observable<Inspections[]>{
    return this.http.delete<Inspections[]>(environment.auditUrl +
                                            'api/Inspections/DeleteSubSectionInspection/' +
                                            subsectionId + '/' +
                                            inspectionId)
    .pipe(map((res: Inspections[]) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }));
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
}
