import { Categories } from 'src/app/models/categories';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit,Input} from '@angular/core';
import { AuditsService } from 'src/app/services/audits.service';
import { Audits } from '../../../models/audits';


@Component({
  selector: 'app-categoryanswersteps',
  templateUrl: './categoryanswersteps.component.html',
  styleUrls: ['./categoryanswersteps.component.css']
})
export class CategoryanswerstepsComponent implements OnInit {

  @Input() auditid: number;
  @Input() selectedAudit:any;

  auditCategories: Categories[] =[];

  constructor(private auditsService: AuditsService, private SpinnerService: NgxSpinnerService) {

  }

  ngOnInit(): void {
  this.analyzeAuditDetails();
  }

  ngOnChange(): void {}

  analyzeAuditDetails()
  {
    if(this.selectedAudit != null)
    {
      this.auditCategories= this.selectedAudit.auditCategories;
    }
  }





}
