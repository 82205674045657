import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';


import { Audits } from 'src/app/models/audits';
import { AuditsService } from 'src/app/services/audits.service';

const roots = [
  {
      label: 'Langages de programmation',
      value: 1,
      children: [
          {
              label: 'C++',
              value: 11
          },
          {
              label: 'Angular',
              value: 12
          },
          {
              label: 'C#',
              value: 13,
              children: [
                  {
                      label: 'LinQ',
                      value: 131
                  },
                  {
                      label: 'UWP',
                      value: 132
                  },
                  {
                      label: 'Sharepoint',
                      value: 133
                  },
                  {
                      label: 'WPF',
                      value: 134
                  }
              ]
          },
          {
              label: 'Java',
              value: 14,
              children: [
                  {
                      label: 'J2E',
                      value: 141
                  },
                  {
                      label: 'Spring Framework',
                      value: 142
                  },
                  {
                      label: 'Vanilla Java',
                      value: 143
                  },
                  {
                      label: 'Android',
                      value: 144
                  }
              ]
          },
          {
              label: 'Empty folder test',
              value: 15,
              children: []
          }
      ]
  },
  {
      value: 1111,
      label: 'Customers',
      children: [
          {
              label: 'Norton',
              value: 156
          },
          {
              label: 'Symantec',
              value: 116
          },
          {
              label: 'Some company',
              value: 126
          },
          {
              label: 'Zokelion',
              value: 196
          }
      ]
  }
]

@Component({
  selector: 'app-audit-treeview',
  templateUrl: './audit-treeview.component.html',
  styleUrls: ['./audit-treeview.component.css']
})


export class AuditTreeviewComponent implements OnInit {

  audit: Audits  = {} as Audits;
  auditId: number;
  auditTree : any[] =[] ;
  public isCollapsed = false;
  public isCollapsed2 = false;



  // auditTree = [
  //   {
  //     label: "Audit Steps" ,
  //     value : 1 ,
  //     children: [
  //       {label : "audit 1" , value : 10 , children : [ {label : "step 1" , value : 101 } , {label : "step 2" , value : 102 } ] },
  //       {label : "audit 2" , value : 11 },
  //       {label : "audit 3" , value : 12 },
  //       {label : "audit 4" , value : 13 },
  //       {label : "audit 5" , value : 14 },
  //     ]
  //   }

  // ] ;

  //tree = roots[0];


  constructor( private router: Router,
    private SpinnerService: NgxSpinnerService,
    private auditservice: AuditsService,) {
     }

  ngOnInit(): void {
    this.loadaudit();

  }

  ngOnChanges(): void {

  }



  backToAuditList()
  {
    this.router.navigateByUrl('listaudits');
  }

  loadaudit(): void{
    const id = window.localStorage.getItem('auditId');
    if (!id) {
      console.log('No Audit Id found');
      return;
    }
    this.SpinnerService.show();
    this.auditId = Number(id);
    this.auditservice.getAuditDetails(this.auditId)
      .subscribe( data => {
        this.SpinnerService.hide();
        this.audit = data;

         this.analyzeDetails();
      });
  }

  defaultStyleLeafClickedEventHandler()
  {
    alert("Tree node is clicked !!");
  }

  private analyzeDetails()
  {


    let auditStepsAsChildren :any[] = [];

    if(this.audit.auditSteps !== null )
    {

      let index : number = 0;
      this.audit.auditSteps.forEach(function (item) {
        let auditStep = item.step ;
        index = index + 10 ;
        let auditStepTreeNode  = { value:item.id , label : auditStep.question  } ;
        auditStepsAsChildren.push(auditStepTreeNode);

      });

      let auditStepsTreeNode  = { value: 1 , label : "Audit Steps" , children : auditStepsAsChildren} ;
      this.auditTree.push(auditStepsTreeNode) ;

    }

    if(this.audit.auditCategories !== null )
    {
      let categories=[] ;
      this.audit.auditCategories.forEach(function (item) {

        let categorySteps =[] ;
        let categorySections=[] ;
        let categoryInspections=[] ;
        let auditCategory = item.category ;

        auditCategory.categorySteps.forEach(function (step) {
          let categoryStep  = { value:step.step.id , label : step.step.question } ;
          categorySteps.push(categoryStep) ;
        }) ;

        auditCategory.categoryInspections.forEach(function(cat){
          let categoryInspection  = { value:cat.inspection.id , label : cat.inspection.name } ;
          categoryInspections.push(categoryInspection) ;
        });

        auditCategory.categorySections.forEach(function(cat){
          let categorySection  = { value:cat.section.id , label : cat.section.name } ;
          categorySections.push(categorySection) ;
        });

        let categoryInfrastructure =[
          { value: 3 , label : "Audit Categories" , children : []} ,
          { value: 4 , label : "Category Inspections" , children : categoryInspections} ,
          { value: 5 , label : "Category Sections" , children : categorySections} ,
          { value: 6 , label : "Category Steps" , children : categorySteps}
        ] ;

        let category  = { value:item.id , label : auditCategory.name , children :categoryInfrastructure } ;
        categories.push(category) ;

      });

      let auditCategoriesTreeNode  = { value: 2 , label : "Audit Categories" , children : categories} ;
      this.auditTree.push(auditCategoriesTreeNode) ;

    }

      console.log(this.auditTree) ;
  }




}

