import { Component, Directive, EventEmitter, Input, Output, QueryList, ViewChildren, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Categories } from 'src/app/models/categories';
import { AuditCategories } from 'src/app/models/audit-categories';
import { CategorystepsComponent } from '../../steps/categorysteps/categorysteps.component';


@Component({
  selector: 'app-auditcategories',
  templateUrl: './auditcategories.component.html',
  styleUrls: ['./auditcategories.component.css']
})
export class AuditcategoriesComponent implements OnInit {

  sortedcategories: Categories[];
  public isStepsCollapsed: boolean[] = [];

  @ViewChild('addCategoryModal') addCategoryModal: ElementRef;
  @ViewChild('editCategoryModal') editCategoryModal: ElementRef;
  @ViewChild('categoryStepsModal') categoryStepsModal: ElementRef;


 // tslint:disable-next-line: no-input-rename
  @Input() auditid: number;

  constructor(private categoryService: CategoryService,
              private router: Router,
              private SpinnerService: NgxSpinnerService,
              private modalService: NgbModal) {
   }

  ngOnInit(): void {
    console.log('ngOnInit ' + this.auditid); // undefined here
    this.loadAuditCategories();
  }

  categoryupdated(t: any){
    this.loadAuditCategories();
  }

  categorycreated(t: any){
    this.categoryService.createauditcategory(this.auditid, t.categoryId).subscribe(res => {
      console.log('Catogery created successfully!');
      this.loadAuditCategories();
      this.SpinnerService.hide();
    });
  }

  private loadAuditCategories() {
    if (this.auditid && this.auditid !== 0)
    {
      this.SpinnerService.show();
      this.categoryService.GetAuditCategories(this.auditid).subscribe((res) => {
        this.SpinnerService.hide();
        this.sortedcategories = res;
      })
        // tslint:disable-next-line: no-unused-expression
        , (err: any) => {
          this.SpinnerService.hide();
          console.log(err);
        };
      }
  }

  private setCategoryId(id: any): void{
    window.localStorage.removeItem('categoryId');
    window.localStorage.setItem('categoryId', id);
  }

  openCategorySteps(e): void{
    this.setCategoryId( e.id.toString());
    const catStepsModal =  this.modalService.open(this.categoryStepsModal, { size: 'xl', backdrop: 'static'});
    // catStepsModal.componentInstance.categoryId = e.id.toString();
  }

  addCategory(): void{
    this.modalService.open(this.addCategoryModal, { size: 'md', backdrop: 'static'});
  }

  editCategory(e): void{
    this.setCategoryId( e.id.toString());
    this.modalService.open(this.editCategoryModal, { size: 'xl', backdrop: 'static'});
  }

  categorydeleted(t: any): void{
    this.SpinnerService.show();
    this.categoryService.deleteauditcategory(this.auditid, t.categoryId).subscribe((res) => {
        console.log('Audit Category deleted successfully!');
        this.loadAuditCategories();
        this.SpinnerService.hide();
      });
  }
}
