<app-topsubnavigation></app-topsubnavigation>
<div class="container">
  <h3>Audit Reports</h3>

  <form>
  <div class="form-group form-inline">
    Search: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="searchTerm"/>
    <span class="ml-3" *ngIf="loading$ | async">Loading...</span>
  </div>

  <!-- Table -->
  <div class="table-responsive">
    <table class="table sh-audit-table">
      <thead>
        <tr>
          <th scope="col" sortable="id" (sort)="onSort($event)" class="sh-title-7">ID</th>
          <th scope="col" sortable="name" (sort)="onSort($event)" class="sh-title-7">Name</th>
          <th scope="col" class="sh-title-7" >Created On</th>
          <th scope="col" class="sh-title-7">Modified On</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of  auditReports$ | async">
          <td scope="row">{{a.id}}</td>
          <td>{{a.name}}</td>
          <td>{{a.createdDate | date:'yyyy-MM-ddTHH:mm'}}</td>
          <td>{{a.modifiedDate | date:'yyyy-MM-ddTHH:mm'}}</td>

        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex p-2 float-right mt-3">
    

    <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="pageSize">
      <option [ngValue]="5">5 items per page</option>
      <option [ngValue]="10">10 items per page</option>
      <option [ngValue]="20">20 items per page</option>
      <option [ngValue]="50">50 items per page</option>
    </select>

    <ngb-pagination
      [collectionSize]="(total$ | async)!" [(page)]="page" [pageSize]="pageSize" [maxSize]="10">
    </ngb-pagination>

  </div>

</form>

</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Loading. </p>
</ngx-spinner>
